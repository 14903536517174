<template>
  <div class="section bg-secondary taller-wrapper">
    <div class="container">
      <div class="row py-4">
        <div class="col-lg-6 mx-auto">
          <div class="card jb-card">
            <div class="card-body">
              <h5 class="mb-4">
                Track Order
                <span class="float-right">
                  <small
                    ><b># {{ order_code }}</b></small
                  >
                </span>
              </h5>
              <hr />
              <div
                class="timeline timeline-one-side"
                data-timeline-content="axis"
                data-timeline-axis-style="dashed"
              >
                <div
                  class="timeline-block"
                  v-for="(track, index) in tracks"
                  :key="'tracks' + index"
                >
                  <span class="timeline-step badge-success">
                    <i class="ti-bell"></i>
                  </span>
                  <div class="timeline-content">
                    <small class="text-muted font-weight-bold">
                      {{ formatOrderDate(track.track_date) }}
                      at {{ explodeString(" ", track.track_date)[1] }}
                    </small>
                    <h5 class="mt-3 mb-0">Order is {{ track.status_type }}</h5>
                    <p class="text-sm mt-1 mb-0">
                      Nullam id dolor id nibh ultricies vehicula ut id elit. Cum
                      sociis natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus.
                    </p>
                    <div class="mt-3" v-if="track.seller_comment">
                      <h6 class="mb-0">Seller comment</h6>
                      <p>{{ track.seller_comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderTracking",
  data: () => ({
    tracks: [],
    order_code: null
  }),
  created() {
    this.getTrackedOrder();
  },
  methods: {
    getTrackedOrder() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "customer/get-order-tracks/" + this.$route.query["access-token"]
        )
        .then(response => {
          this.tracks = response.data.tracks;
          this.order_code = response.data.order.order_code;
        });
    }
  }
};
</script>
<style lang="css" scoped>
.timeline {
  position: relative;
}

.timeline:before {
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  content: "";
  border-right: 2px solid #e9ecef;
}

[data-timeline-axis-style="dashed"]:before {
  border-right-style: dashed !important;
}

[data-timeline-axis-style="dotted"]:before {
  border-right-style: dotted !important;
}

.timeline-block {
  position: relative;
  margin: 2em 0;
}

.timeline-block:after {
  display: table;
  clear: both;
  content: "";
}

.timeline-block:first-child {
  margin-top: 0;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-step {
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  z-index: 1;
  left: 0;
  display: inline-flex;
  width: 33px;
  height: 33px;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.timeline-step i,
.timeline-step svg {
  line-height: 1.4;
}

.timeline-step-icon {
  border: 2px solid #e9ecef;
  background: #fff;
}

.timeline-step-xs {
  font-size: 0.75rem;
  width: 17px;
  height: 17px;
}

.timeline-step-sm {
  font-size: 0.75rem;
  width: 23px;
  height: 23px;
}

.timeline-step-lg {
  font-size: 1.75rem;
  width: 47px;
  height: 47px;
}

.timeline-content {
  position: relative;
  position: relative;
  top: -6px;
  margin-left: 60px;
  padding-top: 0.5rem;
}

.timeline-content:after {
  display: table;
  clear: both;
  content: "";
}

.timeline-body {
  padding: 1.5rem;
}

@media (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: -2px;
  }
  .timeline-step {
    left: 50%;
  }
  .timeline-content {
    width: 38%;
  }
  .timeline-body {
    padding: 1.5rem;
  }
  .timeline-block:nth-child(even) .timeline-content {
    float: right;
  }
  [data-timeline-axis-color="primary"]:before {
    border-color: #5e72e4;
  }
  [data-timeline-axis-color="secondary"]:before {
    border-color: #f7fafc;
  }
  [data-timeline-axis-color="success"]:before {
    border-color: #2dce89;
  }
  [data-timeline-axis-color="info"]:before {
    border-color: #11cdef;
  }
  [data-timeline-axis-color="warning"]:before {
    border-color: #fb6340;
  }
  [data-timeline-axis-color="danger"]:before {
    border-color: #f5365c;
  }
  [data-timeline-axis-color="light"]:before {
    border-color: #adb5bd;
  }
  [data-timeline-axis-color="dark"]:before {
    border-color: #212529;
  }
  [data-timeline-axis-color="default"]:before {
    border-color: #172b4d;
  }
  [data-timeline-axis-color="white"]:before {
    border-color: #fff;
  }
  [data-timeline-axis-color="neutral"]:before {
    border-color: #fff;
  }
  [data-timeline-axis-color="darker"]:before {
    border-color: #000;
  }
}

.timeline-one-side:before {
  left: 1rem;
}

.timeline-one-side .timeline-step {
  left: 1rem;
}

.timeline-one-side .timeline-content {
  width: auto;
}

@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 30rem;
  }
}
.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none;
}
.badge {
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: 0.35rem 0.375rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.375rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
  border-radius: 10rem;
}

.badge-primary {
  color: #2643e9;
  background-color: #eaecfb;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #2a44db;
}

.badge-secondary {
  color: #cfe3f1;
  background-color: #fff;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  text-decoration: none;
  color: #212529;
  background-color: #cadeeb;
}

.badge-success {
  color: #1aae6f;
  background-color: #b0eed3;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #229c68;
}

.badge-info {
  color: #03acca;
  background-color: #aaedf9;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #0c9cb7;
}

.badge-warning {
  color: #ff3709;
  background-color: #fee6e0;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #f93305;
}

.badge-danger {
  color: #f80031;
  background-color: #fdd1da;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #e30b36;
}

.badge-light {
  color: #879cb0;
  background-color: #fff;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #8b96a2;
}

.badge-dark {
  color: #090c0e;
  background-color: #6a7783;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #060607;
}

.badge-default {
  color: #091428;
  background-color: #4172c6;
}

.badge-default[href]:focus,
.badge-default[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #09111e;
}

.badge-white {
  color: #e8e3e3;
  background-color: #fff;
}

.badge-white[href]:focus,
.badge-white[href]:hover {
  text-decoration: none;
  color: #212529;
  background-color: #e0e0e0;
}

.badge-neutral {
  color: #e8e3e3;
  background-color: #fff;
}

.badge-neutral[href]:focus,
.badge-neutral[href]:hover {
  text-decoration: none;
  color: #212529;
  background-color: #e0e0e0;
}

.badge-darker {
  color: #000;
  background-color: #525252;
}

.badge-darker[href]:focus,
.badge-darker[href]:hover {
  text-decoration: none;
  color: #fff;
  background-color: #000;
}
</style>
