<template>
  <div class="section">
    <section
      class="banner v7"
      :style="{ backgroundImage: 'url(' + dukaURL + 'assets/hero-bg.jpg)' }"
    >
      <div class="container mb-2">
        <div class="row mt-5">
          <div class="col-md-6 mx-auto">
            <div class="card text-center mb-3">
              <div class="card-body">
                <h4 class="card-title">Verfying your account...</h4>
                <p class="card-text">
                  We are activating your account. Please wait....
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "LinkVerification",
  data: () => ({
    authenticated: false,
    verified: false,
    invalidCode: false
  }),
  created() {
    this.validateCode();
  },
  methods: {
    validateCode() {
      var formData = this.formData({ given_token: this.$route.params.code });
      let url = "ap/validate-email-token";
      let DispatchpParams = { formData: formData, url: url };
      this.$store.dispatch("authRequest", DispatchpParams).then(response => {
        this.invalidCode = response.data.error === true;
        if (response.data.verified) window.location.replace("/user");
      });
    }
  }
};
</script>
