<template>
  <div class="home-send-money taller-wrapper">
    <section class="py-4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-12">
            <div class="form-tab jb-card">
              <ul class="nav nav-tabs">
                <li>
                  <a class="active" data-toggle="tab" href="javascript:void(0)"
                    >Send Money</a
                  >
                </li>
              </ul>
              <div class="tab-content currency-form">
                <div id="send-money" class="tab-pane fade in active show">
                  <div class="form-field">
                    <label>send amount</label>
                    <div class="join-field">
                      <input
                        type="text"
                        name="send_amount"
                        v-model="amount"
                        placeholder="$1,000.00"
                      />
                      <div class="curr-select">
                        <span class="selected">
                          <img
                            :src="dukaURL + 'assets/images/usa.png'"
                            alt
                          />USD &nbsp;
                          <i class="dripicons-chevron-down"></i>
                        </span>
                        <!-- <ul>
                            <li><a href="home-01.html#"><img src="images/flags/australia.png" alt="">aud</a></li>
                            <li><a href="home-01.html#"><img src="images/flags/canada.png" alt="">cad</a></li>
                          </ul>-->
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn-block btn-filled form-btn"
                    type="button"
                    @click="handleSending"
                  >
                    <span class="bh"></span>
                    <span>
                      Continue Transition
                      <i class="dripicons-arrow-thin-right"></i>
                    </span>
                  </button>
                  <span class="accept-terms">
                    By clicking continue, i am agree with
                    <a href="javascript:void(0)">Terms &amp; Policy</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Landing",
  data: () => ({
    amount: null
  }),
  created() {},
  methods: {
    handleSending() {
      if (this.$store.getters.isLoggedIn)
        window.location.replace(
          "/user/money-transfer?origin-request=direct-send&amount=" +
            this.amount +
            "&currency=USD"
        );
      else
        this.$router.push({
          name: "login",
          query: {
            "origin-request": "money-transfer",
            "redirect-url":
              window.location.origin +
              "/user/money-transfer?origin-request=direct-send&amount=" +
              this.amount +
              "&currency=USD"
          }
        });
    }
  }
};
</script>
<style lang="css"></style>
