<template>
  <div class="section taller-wrapper home-send-money">
    <section class="py-4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="form-tab jb-card">
              <div class="currency-form">
                <div id="send-money" class="tab-pane fade in active show">
                  <h4 class="font-weight-bold mb-2">
                    Create your account
                  </h4>
                  <hr />
                  <form
                    ref="customerForm"
                    autocomplete="off"
                    @submit.prevent="addCustomer"
                  >
                    <div class="form-group">
                      <country-select
                        v-model="customer.location"
                        :country="customer.location"
                        placeholder="Select Country"
                        class="form-control custom-select"
                        topCountry="US"
                      />
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            class="form-control"
                            name="Firstname"
                            v-validate="'required'"
                            type="text"
                            v-model="customer.firstname"
                            placeholder="Firstname"
                            :class="{ 'is-invalid': errors.has('Firstname') }"
                          />
                          <div class="invalid-feedback">
                            {{ errors.first("Lastname") }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            class="form-control"
                            name="Lastname"
                            v-validate="'required'"
                            type="text"
                            v-model="customer.lastname"
                            placeholder="Lastname"
                            :class="{ 'is-invalid': errors.has('Lastname') }"
                          />
                          <div class="invalid-feedback">
                            {{ errors.first("Lastname") }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        class="form-control"
                        type="email"
                        v-validate="'required|email'"
                        name="email"
                        :class="{ 'is-invalid': errors.has('email') }"
                        v-model="customer.email"
                        placeholder="Email"
                      />
                      <div class="invalid-feedback">
                        {{ errors.first("email") }}
                      </div>
                      <span class="text-danger" v-if="emailError"
                        >There is an existing account associated with
                        email.</span
                      >
                    </div>
                    <div class="form-group">
                      <vue-tel-input
                        v-model="customer.phone_number"
                        :inputClasses="'form-control'"
                        :placeholder="'Phone number'"
                        :defaultCountry="'Rwanda'"
                        :validCharactersOnly="true"
                      />
                      <span class="text-danger" v-if="phoneError"
                        >Phone number already exists in our database.</span
                      >
                    </div>

                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        v-validate="'required'"
                        name="password"
                        :class="{ 'is-invalid': errors.has('password') }"
                        placeholder="Password"
                        ref="password"
                        v-model="customer.password"
                      />
                      <div class="invalid-feedback">
                        {{ errors.first("password") }}
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        v-validate="'required|confirmed:password'"
                        name="Password Confirmation"
                        :class="{
                          'is-invalid': errors.has('Password Confirmation')
                        }"
                        data-vv-as="password"
                        placeholder="Confirm Password"
                      />
                      <div class="invalid-feedback">
                        {{ errors.first("Password Confirmation") }}
                      </div>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        id="remember_me"
                        checked
                      />
                      <label class="custom-control-label" for="remember_me"
                        ><span class="accept-terms" style="line-height: 1.4;">
                          I agree to
                          <a href="javascript:void(0)">terms and condiion</a>
                          and
                          <a href="javascript:void(0)">User Privacy Notice</a>
                        </span></label
                      >
                    </div>
                    <button
                      type="submit"
                      class="btn btn-block btn-filled form-btn my-2"
                    >
                      <span
                        class="bh"
                        style="top: 59.125px; left: 95.5px;"
                      ></span>
                      <span>
                        Continue Transition
                      </span>
                    </button>
                    <div class="text-left">
                      Already have a Boyoro account?
                      <router-link
                        :to="{ name: 'login' }"
                        style="text-decoration:underline"
                        >Sign in now?</router-link
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
export default {
  name: "SignUp",
  components: {
    VueTelInput
  },
  data: () => ({
    customer: {
      firstname: null,
      lastname: null,
      location: null,
      email: null,
      phone_number: null,
      password: null
    },
    emailSent: false,
    emailError: false,
    phoneError: false
  }),
  created() {},
  methods: {
    addCustomer() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var formData = this.formData(this.customer);
          let url = "ap/set-customer";
          let DispatchpParams = { formData: formData, url: url };
          this.emailError = false;
          this.phoneError = false;
          this.$store
            .dispatch("authRequest", DispatchpParams)
            .then(response => {
              if (response.data.email_error) this.emailError = true;
              else if (response.data.phone_error) this.phoneError = true;
              else if (!response.data.error) window.location.replace("/user");
            });
        }
      });
    }
  }
};
</script>
