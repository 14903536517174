<template>
  <div class="card jb-card">
    <div class="card-body">
      <template v-if="viewMode.addresses">
        <h5 class="mb-0">
          Your addresses book in talar's platform
          <button
            class="btn btn-sm btn-primary float-right"
            type="button"
            @click="falseObject(viewMode, 'addMode')"
          >
            Add new address
          </button>
        </h5>
        <hr />
        <div class="row">
          <div
            class="col-lg-6"
            v-for="(address, index) in addresses"
            :key="'address' + index"
          >
            <div class="border py-4 px-3 rounded">
              <h6 class="font-weight-bold">{{ address.firstname }}</h6>
              <p class="m-0 text-uppercase">{{ address.address_one }}</p>
              <p class="m-0 text-uppercase">{{ address.address_two }}</p>
              <p class="m-0 text-uppercase">{{ address.country }}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <h5 class="mb-4 pt-sm-3">Add your shipping and contact addresses</h5>
        <form data-vv-scope="form1">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-fn">Personal or company name</label>
                <input
                  class="form-control"
                  type="text"
                  id="checkout-fn"
                  v-model="newShipping.firstname"
                  name="Name"
                  v-validate="'required'"
                  placeholder="Your Name"
                  :class="{ 'is-invalid': errors.has('form1.Name') }"
                />
                <span class="text-danger">{{
                  errors.first("form1.Name")
                }}</span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-country">Country</label>
                <country-select
                  v-model="newShipping.country"
                  :country="newShipping.country"
                  placeholder="Select Country"
                  class="form-control custom-select"
                  topCountry="US"
                  name="Country"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('form1.Country') }"
                />
                <span class="text-danger">{{
                  errors.first("form1.Country")
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-email">E-mail Address</label>
                <input
                  class="form-control"
                  type="email"
                  id="checkout-email"
                  v-model="newShipping.email"
                  name="Email"
                  placeholder="Your Email"
                  v-validate="'required|email'"
                  :class="{ 'is-invalid': errors.has('form1.Email') }"
                />
                <span class="text-danger">{{
                  errors.first("form1.Email")
                }}</span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-phone">Phone Number</label>
                <input
                  class="form-control"
                  type="text"
                  id="checkout-phone"
                  v-model="newShipping.phone"
                  name="Phone number"
                  v-validate="'required'"
                  placeholder="Your Phone number"
                  :class="{ 'is-invalid': errors.has('form1.Phone number') }"
                />
                <span class="text-danger">{{
                  errors.first("form1.Phone number")
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-city">City/state/Province</label>
                <input
                  class="form-control"
                  type="text"
                  id="checkout-city"
                  v-model="newShipping.city"
                  name="City"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('form1.City') }"
                  placeholder="City or State"
                />
                <span class="text-danger">{{
                  errors.first("form1.City")
                }}</span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-zip">ZIP Code</label>
                <input
                  class="form-control"
                  type="text"
                  id="checkout-zip"
                  v-model="newShipping.zip_code"
                  placeholder="Zip Code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-address-1">Address 1</label>
                <input
                  class="form-control"
                  type="text"
                  id="checkout-address-1"
                  v-model="newShipping.address_one"
                  name="Address"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('form1.Address') }"
                  placeholder="Primary address"
                />
                <span class="text-danger">{{
                  errors.first("form1.Address")
                }}</span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-address-2">Address 2</label>
                <input
                  class="form-control"
                  type="text"
                  id="checkout-address-2"
                  v-model="newShipping.address_two"
                  placeholder="Any Other address"
                />
              </div>
            </div>
          </div>
        </form>
        <hr class="mt-2 pb-2" />
        <div class="custom-control custom-checkbox d-block">
          <input
            class="custom-control-input"
            type="checkbox"
            id="same_address"
          />
          <label class="custom-control-label" for="same_address"
            >Mark as your primary address</label
          >
        </div>
        <hr class="mt-3 pb-3" />
        <div class="text-sm-right">
          <button class="btn btn-primary" type="button" @click="setAddress">
            Update address
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "Addreses",
  data: () => ({
    newShipping: {
      firstname: null,
      lastname: null,
      country: null,
      zip_code: null,
      address_one: null,
      address_two: null,
      email: null,
      phone: null,
      company: null,
      city: null
    },
    viewMode: {
      addresses: false,
      addMode: false
    },
    addresses: []
  }),
  created() {
    this.getUserAddress();
  },
  methods: {
    getUserAddress() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-user-addresses")
        .then(response => {
          this.addresses = response.data.addresses;
          this.falseObject(this.viewMode, "addresses");
        });
    },
    setAddress() {
      this.$validator.validateAll("form1").then(valid => {
        if (valid) {
          let url = "customer/set-customer-address";
          var formData = this.formData(this.newShipping);
          let DispatchpParams = { formData: formData, url: url };
          this.$store
            .dispatch("dukaPostRequest", DispatchpParams)
            .then(response => {
              this.$store.dispatch("clearCart").then(() => {
                if (!response.data.error) {
                  this.clearObject(this.newShipping);
                  this.addresses = response.data.addresses;
                  this.falseObject(this.viewMode, "addresses");
                }
              });
            });
        }
      });
    }
  }
};
</script>
