<template>
  <div>
    <section class="pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 my-2">
            <div class="media">
              <i class="dripicons-phone la-4x text-primary mt-2"></i>
              <div class="media-body pl-3">
                <div v-html="contents[0].section_content"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 my-2">
            <div class="media">
              <i class="dripicons-mail la-4x text-primary"></i>
              <div class="media-body pl-3">
                <div v-html="contents[1].section_content"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 my-2">
            <div class="media">
              <i class="dripicons-location la-4x text-primary"></i>
              <div class="media-body pl-2">
                <div v-html="contents[2].section_content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr />
    <section class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto mb-2 text-center">
            <h2>Have Any Questions?</h2>
            <p>
              Please contact us using the form and we’ll get back to you as soon
              as possible.
            </p>
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="form-group mb-4">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Your fullname"
                    v-model="newContact.fullname"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Your Email address"
                    v-model="newContact.email"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <input
                    class="form-control"
                    type="tel"
                    placeholder="Your Mobile number"
                    v-model="newContact.phone"
                  />
                </div>
              </div>
              <div class="col-12">
                <textarea
                  class="form-control"
                  rows="5"
                  placeholder="Your Messgae..."
                  v-model="newContact.message"
                ></textarea>
                <p class="text-success mt-2" v-if="successMsg">
                  Your Query has been submited successfully
                </p>
                <button
                  class="btn btn-primary mt-4 py-3"
                  type="button"
                  @click="proceedEnquire"
                  style="border: unset;"
                >
                  <span>Send Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ContactUs",
  data: () => ({
    newContact: {
      fullname: null,
      phone: null,
      email: null,
      message: null
    },
    successMsg: false,
    contents: []
  }),
  created() {
    this.$store
      .dispatch("dukaGetRequest", "market/get_contact_content/")
      .then(response => {
        this.contents = response.data.contents;
      });
  },
  methods: {
    proceedEnquire() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch("logout").then(() => {
            var formData = this.formData(this.newContact);
            let url = "ap/proceed-inquiry";
            let DispatchpParams = { formData: formData, url: url };
            this.$store
              .dispatch("dukaPostRequest", DispatchpParams)
              .then(response => {
                if (!response.data.error) {
                  this.clearObject(this.newContact);
                  this.successMsg = true;
                }
              });
          });
        }
      });
    }
  }
};
</script>
<style lang="css">
.la-4x {
  font-size: 40px;
}
</style>
