<template>
  <div>
    <duka-modal
      v-if="modalOpen"
      @close="toggleModal()"
      style="display:block"
      :modalSize="'modal-xg'"
    >
      <span slot="head" style="padding-top:10px">
        Order #{{ choosenRow.order_number }}
        <span class="text-primary float-right">{{ choosenRow.status }}</span>
      </span>
      <div slot="body">
        <div class="table-responsive table-responsive-sm">
          <table class="table table-sm align-items-center table-flush">
            <thead>
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Subtotal</th>
                <th>Tax/VAT</th>
                <th>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(detail, index) in choosenRow.details"
                :key="'detail' + index"
              >
                <td class="table-user">
                  <div class="media align-items-center">
                    <img
                      :src="dukaURL + 'uploads/' + detail.product.image"
                      :alt="detail.product.product_title"
                      class="avatar rounded mr-3"
                      v-if="detail.product.image"
                    />
                    <img
                      :src="dukaURL + 'dukamaket-content/avatar.png'"
                      :alt="detail.product.product_title"
                      class="avatar rounded mr-3"
                      v-else
                    />
                    <div class="media-body">
                      <b>{{ detail.product.product_title }}</b>
                      <br />
                      <span
                        v-for="(option, i) in detail.options"
                        :key="i"
                        class="text-danger"
                      >
                        {{ i }} : {{ detail.options[i] }}
                        <br />
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span class="text-muted">{{ detail.quantity_ordered }}</span>
                </td>

                <td>RWF {{ addComma(detail.price_each) }}</td>
                <td>RWF {{ addComma(detail.total_amount) }}</td>
                <td>-</td>
                <td>RWF {{ addComma(detail.total_amount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div slot="foot"></div>
    </duka-modal>
    <div class="card jb-card">
      <div class="card-body">
        <template v-if="orders.length">
          <h4>
            View and tracker your orders
            <span
              class="badge badge-pill badge-primary"
              style="background-color: #041e42!important;"
              >{{ orders.length }}</span
            >
          </h4>
          <div class="table-responsive table-responsive-sm">
            <table
              class="table table-sm align-items-center table-flush table-hover mb-0"
            >
              <thead>
                <tr>
                  <th>Order code</th>
                  <th>Date Purchased</th>
                  <th>Order amount</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in orders" :key="'order' + index">
                  <td>
                    <span># {{ order.order_code }}</span>
                  </td>
                  <td>
                    <span class="text-muted">
                      {{ formatOrderDate(order.order_date) }}
                    </span>
                  </td>

                  <td>RWF {{ addComma(order.order_amount) }}</td>
                  <td>
                    <span
                      class="badge badge-warning m-0"
                      v-if="order.status === 'Awaiting Payment'"
                      >{{ order.status }}</span
                    >
                    <span class="badge badge-primary m-0" v-else>{{
                      order.status
                    }}</span>
                  </td>

                  <td class>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-outline-primary btn-sm"
                      @click.prevent="
                        selectRecord(order);
                        toggleModal();
                      "
                    >
                      <i class="icon-list"></i>
                      Details
                    </a>
                    <router-link
                      type="button"
                      class="btn btn-primary btn-sm"
                      :to="{
                        name: 'ordertracking',
                        params: { origin: 'customer-account' },
                        query: { 'access-token': order.access_token }
                      }"
                      >Track</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="alert alert-danger w-100">
            <div class="iziToast-body" style="padding-left: 33px;">
              <h6 class="mb-1">
                <i class="ti-alert"></i>
                No products orders found for you.
              </h6>
              <p class="text-left" style="margin:0!important">
                You have not place any product order at Talar. Please visit our
                shop to start purchasing now.
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerOrder",
  data() {
    return {
      orders: []
    };
  },
  created() {
    this.loadOrders();
  },
  methods: {
    loadOrders() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-orders")
        .then(response => {
          this.orders = response.data.orders;
        });
    }
  }
};
</script>
<style scoped>
.customer-info,
.customer-avatar {
  display: inline-table !important;
}
.avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 0.375rem;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}
</style>
