var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container py-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('h4',[_vm._v(" Search Results for: "),_c('b',[_vm._v(_vm._s(_vm.$route.query.q))])]),_c('hr'),_c('div',{staticClass:"d-flex flex-wrap justify-content-center justify-content-sm-between pb-3"},[_c('div',{staticClass:"d-none d-sm-flex flex-wrap"},[_c('div',{staticClass:"form-inline flex-nowrap pb-3"},[_c('label',{staticClass:"mr-2 d-none d-sm-block",attrs:{"for":"number"}},[_vm._v("Show:")]),_c('select',{staticClass:"form-control custom-select mr-sm-2",attrs:{"id":"number"},on:{"change":function($event){return _vm.changePaginationRows($event)}}},[_c('option',[_vm._v("12")]),_c('option',[_vm._v("24")]),_c('option',[_vm._v("48")]),_c('option',[_vm._v("96")])]),_c('small',{staticClass:"form-text text-muted text-nowrap d-none d-sm-block"},[_vm._v("From "+_vm._s(_vm.recordsLength)+" ")])])]),_c('div',{staticClass:"form-inline d-none d-sm-flex flex-nowrap pb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterKey),expression:"filterKey"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Search for a product"},domProps:{"value":(_vm.filterKey)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filterKey=$event.target.value}}})])]),_c('div',{staticClass:"row"},_vm._l((_vm.filteredProducts),function(product,index){return _c('div',{key:'products' + index,staticClass:"col-lg-4 col-sm-6 col-12 mb-3"},[_c('div',{staticClass:"product-card mb-4"},[_c('div',{staticClass:"product-thumb"},[_c('router-link',{attrs:{"to":{
                    name: 'shopsingle',
                    params: {
                      category_slug: product.category_slug,
                      slug: product.product_slug
                    }
                  }}},[_c('img',{attrs:{"src":_vm.dukaURL + 'uploads/' + product.image,"alt":product.product_title}})])],1),_c('div',{staticClass:"product-card-body text-center"},[_c('router-link',{staticClass:"product-meta",attrs:{"to":{
                    name: 'shop',
                    params: { slug: product.category_slug }
                  }}},[_vm._v(_vm._s(product.category_name))]),_c('h3',{staticClass:"product-card-title two-line"},[_c('router-link',{attrs:{"to":{
                      name: 'shopsingle',
                      params: {
                        category_slug: product.category_slug,
                        slug: product.product_slug
                      }
                    }}},[_vm._v(_vm._s(product.product_title))])],1),_c('h6',{staticClass:"text-danger font-weight-light mb-0"},[_vm._v(" RWF "+_vm._s(_vm.salePrice(product.orginal_price, product.retail_price))+" ")]),_c('h6',{staticClass:"text-dark font-weight-light"},[(Number(product.retail_price))?[_c('s',[_vm._v("RWF "+_vm._s(_vm.addComma(product.orginal_price)))]),_vm._v(" "),_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(product.retail_price)+"% off ")])]:[_vm._m(0,true)]],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 px-1"},[_c('button',{staticClass:"btn btn-small btn-block btn-primary",on:{"click":function($event){return _vm.addToCart(product)}}},[_vm._v(" Add to cart ")])]),_c('div',{staticClass:"col-6 px-1"},[_c('button',{staticClass:"btn btn-small btn-secondary btn-block",attrs:{"type":"button"},on:{"click":function($event){return _vm.buyNow(product)}}},[_vm._v(" Buy now ")])])])],1)])])}),0),_c('hr',{staticClass:"pb-4 mb-2"}),_c('pagination',{attrs:{"current_page":_vm.currentPage,"row_count_page":_vm.rowCountPage,"total_records":_vm.totalRows,"page_range":_vm.pageRange,"prevIcon":"ti-arrow-left","nextIcon":"ti-arrow-right","dotsIcon":"ti-more"},on:{"page-update":_vm.pageUpdate}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br')])}]

export { render, staticRenderFns }