<template>
  <div class="section bg-secondary taller-wrapper">
    <div class="py-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <div class="card jb-card">
              <div class="card-body">
                <h2 class="h3 pb-3">Order Details</h2>
                <p class="pb-4">
                  Enter your order number in the box bellow to review your
                  purchase and view its delivery status
                </p>
                <p class="">
                  If you've just placed an order, it will take a few minutes to
                  update
                </p>
                <div class="mt-3">
                  <label>Order number *</label>
                  <form class="validate row" @submit.prevent="searchForOrder">
                    <div class="mb-2 col-lg-8 col-md-8 col-12 px-lg-0 px-md-0">
                      <input
                        class="form-control"
                        type="text"
                        v-validate="'required'"
                        v-model="newSearch.order_code"
                        name="Order Code"
                        :class="{ 'is-invalid': errors.has('Order Code') }"
                        placeholder="Order code here"
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <button class="btn btn-primary btn-block" type="submit">
                        Search
                      </button>
                    </div>
                  </form>
                  <div
                    class="invalid-feedback"
                    :class="{ 'd-block': errors.has('Order Code') }"
                  >
                    {{ errors.first("Order Code") }}
                  </div>
                </div>
                <div
                  class="alert alert-danger alert-with-icon"
                  role="alert"
                  v-if="invalidCode"
                >
                  <div class="alert-icon-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-slash alert-icon"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
                    </svg>
                  </div>
                  invalid <strong>Order code</strong>. It doesn't belong to any
                  of our orders.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderSearch",
  data: () => ({
    newSearch: {
      order_code: null
    },
    invalidCode: false
  }),
  methods: {
    searchForOrder() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let url = "market/search-for-order";
          var formData = this.formData(this.newSearch);
          let DispatchpParams = { formData: formData, url: url };
          this.$store
            .dispatch("dukaPostRequest", DispatchpParams)
            .then(response => {
              if (!response.data.order) this.invalidCode = true;
              else
                this.$router.replace({
                  name: "ordertracking",
                  params: { origin: "direct-track" },
                  query: { "access-token": response.data.order.access_token }
                });
            })
            .catch(err => console.log(err));
        }
      });
    }
  }
};
</script>
