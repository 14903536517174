<template>
  <div class="section home-send-money taller-wrapper">
    <div class="container py-4">
      <div class="row">
        <div class="col-md-6 mx-auto" v-if="!verified">
          <div class="card text-center jb-card">
            <div class="card-body">
              <h4 class="card-title">Verfying your account...</h4>
              <p class="card-text">
                We are activating your account. Please wait....
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-5 mx-auto" v-else>
          <div class="card jb-card">
            <div class="card-body">
              <div class="alert alert-success">
                Your company email has been verified successfullly.
              </div>
              <h5 class="mb-4 text-center">
                Please Create a password to authenticate your company's account
              </h5>
              <div class="form-group">
                <input
                  class="form-control"
                  type="password"
                  v-validate="'required'"
                  name="password"
                  :class="{ 'is-invalid': errors.has('password') }"
                  placeholder="Password"
                  ref="password"
                  v-model="newCompany.password"
                />
                <div class="invalid-feedback">
                  {{ errors.first("password") }}
                </div>
              </div>

              <div class="form-group">
                <input
                  class="form-control"
                  type="password"
                  v-validate="'required|confirmed:password'"
                  name="Password Confirmation"
                  :class="{
                    'is-invalid': errors.has('Password Confirmation')
                  }"
                  data-vv-as="password"
                  placeholder="Confirm Password"
                />
                <div class="invalid-feedback">
                  {{ errors.first("Password Confirmation") }}
                </div>
              </div>
              <button
                type="button"
                @click="createPassword"
                class="btn btn-block btn-filled form-btn"
              >
                <span class="bh" style="top: 59.125px; left: 95.5px;"></span>
                <span>
                  Create Password
                  <i class="dripicons-arrow-thin-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompanyVerification",
  data: () => ({
    authenticated: false,
    verified: false,
    invalidCode: false,
    newCompany: {
      password: null,
      code: null
    }
  }),
  created() {
    this.validateCode();
  },
  methods: {
    validateCode() {
      var formData = this.formData({ code: this.$route.params.code });
      let url = "customer/check-company-existence";
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          this.invalidCode = response.data.error === true;
          if (response.data.activated) this.verified = true;
        });
    },
    createPassword() {
      this.newCompany.code = this.$route.params.code;
      var formData = this.formData(this.newCompany);
      let url = "customer/update-company-password";
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.$router.push({ name: "login" });
          }
        });
    }
  }
};
</script>
