<template>
  <div class="taller-wrapper">
    <duka-alert
      :modalTitle="alertDefaults.title"
      :modalContent="alertDefaults.content"
      :actionButton="alertDefaults.actionButton"
      @accept-alert="toggleActions(alertDefaults.actionType)"
      @close="closeAlert"
      :actionButtonClasses="alertDefaults.classes"
      v-if="alertDefaults.modalOpen"
    />
    <div class="container pb-5 mt-5">
      <div class="row pt-5">
        <div class="col-xl-9 col-md-8">
          <h2
            class="h6 d-flex flex-wrap justify-content-between align-items-center px-4 py-3 bg-secondary"
          >
            <span>Products</span>
            <router-link class="font-size-sm" :to="{ name: 'home' }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-left"
                style="width: 1rem; height: 1rem;"
              >
                <polyline points="15 18 9 12 15 6" /></svg
              >Continue shopping
            </router-link>
          </h2>
          <!-- Item-->
          <div
            class="d-sm-flex justify-content-between my-4 pb-4 border-bottom"
            v-for="(product, index) in cartProducts"
            :key="'cartproduct' + index"
          >
            <div
              class="media d-block d-sm-flex text-center text-sm-left align-items-center"
            >
              <a
                class="cart-item-thumb mx-auto mr-sm-4"
                href="javascript:void(0)"
              >
                <img
                  :src="dukaURL + 'uploads/' + product.product_image"
                  :alt="product.product_title"
                />
              </a>
              <div class="media-body pt-3">
                <h3
                  class="product-card-title font-weight-semibold border-0 pb-0"
                >
                  <a href="javascript:void(0)" class="ellipsis-text">{{
                    product.product_title
                  }}</a>
                </h3>
                <template v-if="!isEmpty(product.options)">
                  <div class="font-size-sm">
                    <span class="text-muted mr-2">{{
                      treatAttributes(product.options)[0]
                    }}</span>
                  </div>
                  <div class="font-size-sm">
                    <span class="text-muted mr-2">{{
                      treatAttributes(product.options)[1]
                    }}</span>
                  </div>
                </template>
                <h6 class="text-danger font-weight-light mb-1">
                  RWF {{ salePrice(product.price, product.retail_price) }}
                </h6>
                <h6 class="text-dark font-weight-light">
                  <template v-if="Number(product.retail_price)">
                    <s>RWF {{ addComma(product.price) }}</s> &nbsp;
                    <span class="text-primary">
                      {{ product.retail_price }}% off
                    </span>
                  </template>
                  <template v-else>
                    <div>
                      <br />
                    </div>
                  </template>
                </h6>
              </div>
            </div>
            <div
              class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left"
              style="max-width:10rem;"
            >
              <div class="form-group mb-2">
                <label for="quantity1">Quantity</label>
                <div class="d-flex">
                  <button
                    type="button"
                    style="border-right:none; border-bottom-right-radius:0;
                                border-top-right-radius:0"
                    class="btn btn-sm btn-secondary qty-btn mr-0"
                    @click="
                      decrementObjectKey(cartProducts[index], 'qty', 1);
                      updateProductQty(product.product_id, index);
                    "
                  >
                    -
                  </button>
                  <input
                    type="text"
                    style="border-radius:0"
                    class="form-control form-control-sm"
                    v-model="cartProducts[index].qty"
                    :id="'qty' + index"
                  />
                  <button
                    type="button"
                    style="border-left:none; border-bottom-left-radius:0;
                                border-top-left-radius:0"
                    class="btn btn-sm btn-secondary qty-btn"
                    @click="
                      incrementObjectKey(cartProducts[index], 'qty');
                      updateProductQty(product.product_id, index);
                    "
                  >
                    +
                  </button>
                </div>
              </div>

              <button
                class="btn btn-outline-danger btn-sm btn-block mb-2"
                type="button"
                @click="
                  selectRecord(product);
                  productDelete(choosenRow.product_title);
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 mr-1"
                >
                  <polyline points="3 6 5 6 21 6" />
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" /></svg
                >Remove
              </button>
            </div>
          </div>
          <!-- Item-->

          <!-- Item-->

          <!-- Item-->
        </div>
        <!-- Sidebar-->
        <div class="col-xl-3 col-md-4 pt-3 pt-md-0">
          <h2 class="h6 px-4 py-3 bg-secondary text-center">Order summary</h2>
          <div class="font-size-sm border-bottom pt-2 pb-3">
            <div class="d-flex justify-content-between mb-2">
              <span>Subtotal:</span>
              <span>RWF {{ addComma(grandTotal) }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>Shipping:</span>
              <span>—</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>Taxes:</span>
              <span>RWF 0.00</span>
            </div>
            <div class="d-flex justify-content-between">
              <span>Discount:</span>
              <span>—</span>
            </div>
          </div>

          <div
            class="h6 font-weight-semibold d-flex justify-content-between pt-3"
          >
            <small class="text-danger">Grand total</small>
            <span>RWF {{ addComma(grandTotal) }}</span>
          </div>
          <hr />
          <template v-if="false">
            <h3 class="h6 pt-4 font-weight-semibold">
              <span class="badge badge-success mr-2">Note</span>Additional
              comments
            </h3>
            <textarea
              class="form-control mb-3"
              id="order-comments"
              rows="5"
            ></textarea>
          </template>
          <button
            type="button"
            class="btn btn-primary btn-block"
            @click="startCheckout"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-credit-card mr-2"
            >
              <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
              <line x1="1" y1="10" x2="23" y2="10" /></svg
            >Proceed to Checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShoppingCart",
  data: () => ({
    cartProducts: [],
    totalCartAmount: 0,
    grandTotal: 0,
    discount: 0
  }),
  created() {
    this.cartProducts = this.parseItems();
    this.getCartTotalAmount();
    this.calculateDiscount();
  },
  methods: {
    getCartTotalAmount() {
      this.totalCartAmount = this.cartTotal();
    },
    clearCart: function() {
      this.$store.dispatch("clearCart").then(() => {
        this.$root.$emit("newCartItemsListner", this.afteraCart());
        this.$router.push({ name: "home" });
      });
    },
    removeCartItem: function() {
      this.$store.dispatch("cartRemove", this.choosenRow).then(() => {
        this.cartProducts = this.parseItems();
        this.getTotalItems();
        this.getCartTotalAmount();
        this.calculateDiscount();
        this.$root.$emit("newCartItemsListner", this.afteraCart());
      });
    },
    startCheckout() {
      if (this.$store.getters.isLoggedIn)
        this.$router.push({ name: "checkoutprocess" });
      else
        this.$router.push({
          name: "login",
          params: { billingRequest: true },
          query: {
            "origin-request": "shopping-cart",
            "redirect-url":
              window.location.origin + "/shopping/check-out-process"
          }
        });
    },
    toggleActions(action) {
      switch (action) {
        case "products_removal":
          this.clearCart();
          break;
        case "product_delete":
          this.removeCartItem();
          break;
        default:
          console.log("default data");
          break;
      }
      this.alertDefaults.modalOpen = false;
    },
    productsRemove() {
      this.alertDefaults = {
        title: "Clearing cart",
        content:
          "Are you sure you want to remove all products from shopping cart?",
        actionType: "products_removal",
        actionButton: "Yes, clear",
        classes: "btn btn-danger",
        modalOpen: true
      };
    },
    productDelete(product_title) {
      this.alertDefaults = {
        title: "Removing cart item",
        content:
          "Are you sure you want to remove this product fro shopping cart: <b>" +
          product_title +
          "</b>",
        actionType: "product_delete",
        actionButton: "Yes, remove",
        classes: "btn btn-danger",
        modalOpen: true
      };
    },
    updateProductQty(id, indexx) {
      var updateValue = document.getElementById("qty" + indexx).value;
      if (updateValue) {
        let cart = { product_id: id, qty: updateValue };
        this.$store.dispatch("cartQtyRequest", cart).then(() => {
          this.$root.$emit("newCartItemsListner", this.afteraCart());
          this.getCartTotalAmount();
          this.calculateDiscount();
        });
      }
    },
    calculateDiscount() {
      let cartProducts = this.parseItems();
      let grandTotal = 0;
      cartProducts.forEach(product => {
        grandTotal += Number(this.getCartDiscount(product).replace(/,/g, ""));
      });
      this.grandTotal = grandTotal;
    },

    getDiscount(product) {
      return (
        this.getProdutSubTotal(product) -
        this.getCartDiscount(product).replace(/,/g, "")
      );
    }
  }
};
</script>
