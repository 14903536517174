<template>
  <section class="error-section talar-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8 m-auto">
          <img :src="dukaURL + 'assets/images/404.png'" alt="" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-9 m-auto text-center">
          <div class="sec-heading">
            <h2 class="sec-title">Oops! Page Not Found</h2>

            <p class="sec-subtitle">
              It seems we can’t find the page you are looking for. Go back to
              home page Or try using search at the top of the page.
              <router-link to="/" class="btn btn-filled btn-round"
                >Go back to Homepage</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Error404"
};
</script>
