<template>
  <section class="pt-0 taller-wrapper">
    <div class="container-fluid mt-1">
      <div class="row bg-white pt-1" v-if="!isEmpty(product)">
        <!-- Poduct Gallery-->
        <div class="col-lg-6">
          <div class="product-gallery">
            <Slick ref="ProductSlick" :options="ProductsOptions">
              <div class="product-carousel">
                <a href="javascript:void(0)">
                  <img
                    :src="dukaURL + 'uploads/' + product.image"
                    :alt="product.product_title"
                    class="img-fluid mx-auto"
                  />
                </a>
              </div>
              <div
                class="product-carousel"
                v-for="(image, index) in product.gallery"
                :key="'gallery' + index"
              >
                <a href="javascript:void(0)">
                  <img
                    :src="dukaURL + 'uploads/' + image"
                    :alt="product.product_title"
                    class="img-fluid mx-auto"
                  />
                </a>
              </div>
            </Slick>
          </div>
        </div>
        <!-- Product Info-->
        <div class="col-lg-6 pt-4 pt-lg-0">
          <div class="d-none d-lg-block">
            <h3 class="page-title pt-1">{{ product.product_title }}</h3>
            <h4 class="text-primary font-weight-light pt-3 pb-2">
              RWF {{ addComma(product.orginal_price) }}
            </h4>
          </div>

          <div class="padding-top-2x mt-2 hidden-md-up"></div>
          <span class="h4 d-block"></span>
          <div class="row margin-top-1x">
            <div
              class="col-sm-6"
              v-for="(variant, indexxa) in product.variations"
              :key="'vvariants' + indexxa"
            >
              <div class="form-group">
                <label style="width:100%!important">{{
                  variant.variation_name
                }}</label>
                <template v-if="variant.variation_type === 'dropdown'">
                  <select
                    class="form-control"
                    id="size"
                    v-model="currentOptions[variant.variation_name]"
                  >
                    <option
                      v-for="(valw, indexxxa) in variant.variation_values"
                      :key="'valueees' + indexxa + indexxxa"
                      >{{ valw.valuee }}</option
                    >
                  </select>
                </template>
                <template v-if="variant.variation_type === 'radio'">
                  <div
                    class="custom-control custom-radio custom-control-inline"
                    v-for="(valw, indexxxa) in variant.variation_values"
                    :key="'valueees' + indexxa + indexxxa"
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      :id="'ex-radio' + indexxa + indexxxa"
                      name="radio"
                      v-model="currentOptions[variant.variation_name]"
                      :value="valw.valuee"
                    />
                    <label
                      class="custom-control-label"
                      :for="'ex-radio' + indexxa + indexxxa"
                      >{{ valw.valuee }}</label
                    >
                  </div>
                </template>
                <template v-if="variant.variation_type === 'rectangular'">
                  <div class="form-group">
                    <div
                      class="custom-control custom-option custom-control-inline mb-2"
                      v-for="(valw, indexxxa) in variant.variation_values"
                      :key="'valueees' + indexxa + indexxxa"
                      :style="{ backgroundColor: valw.valuee }"
                    >
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="color"
                        :value="valw.valuee"
                        :id="'valueees' + indexxa + indexxxa"
                        required
                      />
                      <label class="custom-option-label" for="dark">
                        <span
                          class="custom-option-color"
                          :style="{ backgroundColor: valw.valuee }"
                        ></span>
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-4">
              <span>Quantity</span>
              <div class="d-flex">
                <button
                  type="button"
                  style="border-right:none; border-bottom-right-radius:0;
                                border-top-right-radius:0"
                  class="btn btn-sm btn-secondary mr-0"
                  @click="
                    decrementObjectKey(newCart, 'quantity', product.min_qty)
                  "
                >
                  -
                </button>
                <input
                  type="text"
                  style="border-radius:0;height: 42px;"
                  class="form-control text-center"
                  v-model="newCart.quantity"
                />
                <button
                  type="button"
                  style="border-left:none; border-bottom-left-radius:0;
                                border-top-left-radius:0"
                  class="btn btn-sm btn-secondary"
                  @click="incrementObjectKey(newCart, 'quantity')"
                >
                  +
                </button>
              </div>
            </div>

            <div class="col-4">
              <div class="pt-4 hidden-sm-up"></div>
              <button
                class="btn btn-block btn-primary"
                @click="addToCart(product)"
              >
                <i class="icon-bag"></i> Add to cart
              </button>
            </div>
            <div class="col-4">
              <div class="pt-4 hidden-sm-up"></div>
              <button
                class="btn btn-secondary btn-block"
                type="button"
                @click="buyNow(product)"
              >
                <i class="icon-bag"></i> Buy now
              </button>
            </div>
            <div class="col-sm-12 text-danger" v-if="qtyError">
              Please select Quantity
            </div>
          </div>
          <div class="row pt-4 bg-white">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="accordion-heading">
                    <span
                      class="d-inline-block pr-2 border-right mr-2 align-middle mt-n1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-info"
                        style="width: 1.1rem; height: 1.1rem;"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12" y2="8" />
                      </svg>
                    </span>
                    Product information
                  </h4>
                </div>
                <div
                  class="collapse show"
                  id="productInfo"
                  data-parent="#productPanels"
                >
                  <div class="card-body">
                    <p class="mb-4" v-html="product.description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4" v-if="similarProducts.length">
        <div class="col-lg-12">
          <h2 class="h3 widget-title jb-title mt-2">You may also like</h2>
          <div class="d-lg-block jb-carousel pt-4">
            <carousel
              :items="5"
              :nav="true"
              :dots="true"
              :margin="10"
              :responsive="{
                0: { items: 2, dots: false },
                600: { items: 5, dots: false }
              }"
            >
              <div
                class="product-card"
                v-for="(product, index) in similarProducts"
                :key="'similar' + index"
              >
                <div class="product-thumb">
                  <a
                    class="product-thumb-link"
                    :href="
                      $router.resolve({
                        name: 'shopsingle',
                        params: {
                          category_slug: product.category_slug,
                          slug: product.product_slug
                        }
                      }).href
                    "
                  ></a>

                  <img
                    :src="dukaURL + 'uploads/' + product.image"
                    :alt="product.product_title"
                  />
                </div>
                <div class="product-card-body">
                  <div class="d-flex flex-wrap justify-content-between pb-1">
                    <router-link :to="{}" class="product-meta">{{
                      product.category_name
                    }}</router-link>
                  </div>
                  <h3 class="product-card-title two-line">
                    <a
                      :href="
                        $router.resolve({
                          name: 'shopsingle',
                          params: {
                            category_slug: product.category_slug,
                            slug: product.product_slug
                          }
                        }).href
                      "
                      >{{ product.product_title }}</a
                    >
                  </h3>
                  <span class="text-primary"
                    >RWF {{ addComma(product.orginal_price) }}</span
                  >
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Slick from "vue-slick";

export default {
  name: "ProductSingle",
  components: {
    Slick
  },
  data: () => ({
    product: {},
    similarProducts: [],
    steps: [],
    currentOptions: {},
    ProductsOptions: {
      slidesToShow: 1,
      dots: true,
      slidesToScroll: 1,
      arrows: true,
      prevArrow:
        '<button type="button" class="slick-prev"><i class="dripicons-chevron-left"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next"><i class="dripicons-chevron-right"></i></button>'
    },
    similarsOptions: {
      slidesToShow: 4,
      dots: true,
      slidesToScroll: 4,
      arrows: true
    },
    newCart: {
      quantity: null
    }
  }),
  created() {
    this.getproductData();
  },
  updated() {
    this.$nextTick(() => {
      document
        .getElementsByClassName("owl-prev")
        .forEach(x => (x.innerHTML = '<i class="dripicons-chevron-left"></i>'));
      document
        .getElementsByClassName("owl-next")
        .forEach(
          x => (x.innerHTML = '<i class="dripicons-chevron-right"></i>')
        );
    });
  },
  methods: {
    getproductData() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "market/product-single/" + this.$route.params.slug
        )
        .then(response => {
          this.product = response.data.product;
          this.similarProducts = response.data.similars;
          let minQty =
            response.data.product.min_qty !== null &&
            response.data.product.min_qty > 0
              ? response.data.product.min_qty
              : 1;
          this.newCart.quantity = minQty;
          this.setCurrentOptions(response.data.product);
          this.ProductsOptions.customPaging = (slick, index) => {
            var imgSrc = slick.$slides
              .eq(index)
              .find("img")
              .attr("src");
            return (
              '<a href="javascript:void(0)"><img src=" ' +
              imgSrc +
              ' "></a></li>'
            );
          };
          this.$nextTick(() => {
            this.$refs.ProductSlick.reSlick();
            document
              .getElementsByClassName("slick-dots")[0]
              .classList.add("product-thumbnails");
          });

          //this.carouselSteps()
        });
    },
    setCurrentOptions(product) {
      if (product.variations.length) {
        product.variations.forEach(variant => {
          var defaultValue = null;
          for (let valw of variant.variation_values) {
            if (Number(valw.isDefault)) {
              defaultValue = valw.valuee;
              break;
            }
          }
          this.currentOptions[variant.variation_name] = defaultValue;
        });
      }
    },
    carouselSteps() {
      let test = this.similarProperties.length;
      this.steps.push({ s: 0, e: 2 });
      if (test >= 3) {
        for (var i = 2; i <= test; i += 3) {
          if (i + 3 >= test) this.steps.push({ s: i + 1, e: test });
          else this.steps.push({ s: i + 1, e: i + 3 });
        }
      }
    },
    addToCart(product, loan_request = false) {
      if (this.newCart.quantity) {
        this.qtyError = false;
        const successText =
          loan_request === true
            ? "Added successfully to loan cart"
            : "Product added succcessfully in shopping cart";
        let cart = {
          row_id: this.uuidv4(),
          product_id: product.master_id,
          price: product.orginal_price,
          retail_percentage: product.retail_perc || 0,
          retail_price: product.retail_price || 0,
          product_title: product.product_title,
          product_image: product.image,
          qty: this.newCart.quantity,
          ref_key: product.user_id,
          options: this.currentOptions,
          isLoan: loan_request
        };
        this.$store.dispatch("cartRequest", cart).then(() => {
          if (loan_request === true) {
            this.$root.$emit("newLoanItemsListner", this.afteraCart(true));
            this.$notify({
              group: "foo",
              title: "Loan cart",
              type: "warning",
              text: successText
            });
            this.product.loan.calculator = this.loanCalculator;
            this.$store.dispatch("currentLoanRequest", this.product.loan);
          } else {
            this.$root.$emit("newCartItemsListner", this.afteraCart());
            this.$notify({
              group: "foo",
              title: "Shopping cart",
              type: "success",
              text: successText
            });
          }
        });
      } else {
        this.qtyError = true;
      }
    },

    buyNow(product) {
      this.$store.dispatch("clearCart").then(() => {
        let cart = {
          row_id: this.uuidv4(),
          product_id: product.master_id,
          price: product.orginal_price,
          retail_percentage: product.retail_perc || 0,
          retail_price: product.retail_price || 0,
          product_title: product.product_title,
          product_image: product.image,
          qty: this.newCart.quantity,
          ref_key: product.user_id,
          options: this.currentOptions,
          isLoan: false
        };
        this.$store.dispatch("cartRequest", cart).then(() => {
          this.$root.$emit("newCartItemsListner", this.afteraCart());
          this.$router.push({ name: "shoppingcart" });
        });
      });
    }
  }
};
</script>
<style lang="css"></style>
