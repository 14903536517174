var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"taller-wrapper"},[(_vm.heroBanners.length)?_c('section',{staticClass:"hero-slider py-5",style:({
      'background-image':
        'url(' + _vm.dukaURL + 'uploads/hero-slider/main-bg.jpg)'
    })},[_c('carousel',{attrs:{"nav":true,"dots":true,"loop":true,"autoplay":true,"autoplayTimeout":7000,"items":1}},_vm._l((_vm.heroBanners),function(banner,index){return _c('div',{key:'banner' + index,staticClass:"container padding-top-3x"},[_c('div',{staticClass:"row justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-5 col-md-6 text-md-left text-center"},[_c('div',{staticClass:"from-bottom"},[_c('div',{staticClass:"h2 text-body text-normal mb-2"},[_vm._v(" "+_vm._s(banner.banner_title)+" ")]),_c('div',{staticClass:"h2 text-body text-normal mb-4 pb-1"},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(banner.banner_subtitle))])])]),_c('router-link',{staticClass:"btn btn-primary scale-up delay-1",attrs:{"to":{ name: 'shop' }}},[_vm._v(_vm._s(banner.url_label))])],1),_c('div',{staticClass:"col-md-5 pb-2"},[_c('img',{staticClass:"d-block mx-auto",attrs:{"src":_vm.dukaURL + 'uploads/home-slide/' + banner.image,"alt":banner.banner_title}})])])])}),0)],1):_vm._e(),(_vm.topCategories.length)?_c('section',{staticClass:"py-3"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 cat_slider cat_style1 mt-4 mt-md-0"},[_c('carousel',{attrs:{"loop":true,"dots":false,"nav":false,"margin":30,"responsive":{
              0: { items: 2 },
              480: { items: 3 },
              576: { items: 4 },
              768: { items: 5 },
              991: { items: 6 },
              1199: { items: 7 }
            }}},_vm._l((_vm.topCategories),function(category,index){return _c('div',{key:'topcategory' + index,staticClass:"categories_box"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":(_vm.dukaURL + "uploads/categories/" + (category.image)),"alt":"cat_img1"}}),_c('span',[_vm._v(_vm._s(category.category_name))])])])}),0)],1)])])]):_vm._e(),_c('section',{staticClass:"pt-0 px-3 pb-5",staticStyle:{"background-color":"#fcfcfc"}},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row"},_vm._l((_vm.products),function(product,index){return _c('div',{key:'products' + index,staticClass:"col-lg-3 col-sm-4 col-12 mb-3"},[_c('div',{staticClass:"product-card"},[_c('div',{staticClass:"product-thumb"},[_c('router-link',{attrs:{"to":{
                  name: 'shopsingle',
                  params: {
                    category_slug: product.category_slug,
                    slug: product.product_slug
                  }
                }}},[_c('img',{attrs:{"src":_vm.dukaURL + 'uploads/' + product.image,"alt":product.product_title}})])],1),_c('div',{staticClass:"product-card-body text-center"},[_c('router-link',{staticClass:"product-meta",attrs:{"to":{
                  name: 'shop',
                  params: { slug: product.category_slug }
                }}},[_vm._v(_vm._s(product.category_name))]),_c('h3',{staticClass:"product-card-title two-line"},[_c('router-link',{attrs:{"to":{
                    name: 'shopsingle',
                    params: {
                      category_slug: product.category_slug,
                      slug: product.product_slug
                    }
                  }}},[_vm._v(_vm._s(product.product_title))])],1),_c('h6',{staticClass:"text-danger font-weight-light mb-0"},[_vm._v(" RWF "+_vm._s(_vm.salePrice(product.orginal_price, product.retail_price))+" ")]),_c('h6',{staticClass:"text-dark font-weight-light"},[(Number(product.retail_price))?[_c('s',[_vm._v("RWF "+_vm._s(_vm.addComma(product.orginal_price)))]),_vm._v(" "),_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(product.retail_price)+"% off ")])]:[_vm._m(2,true)]],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 px-1"},[_c('button',{staticClass:"btn btn-small btn-block btn-primary",on:{"click":function($event){return _vm.addToCart(product)}}},[_vm._v(" Add to cart ")])]),_c('div',{staticClass:"col-6 px-1"},[_c('button',{staticClass:"btn btn-small btn-secondary btn-block",attrs:{"type":"button"},on:{"click":function($event){return _vm.buyNow(product)}}},[_vm._v(" Buy now ")])])])],1)])])}),0)])]),_c('section',{staticClass:"container py-4 pb-md-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-6 mb-1"},[_c('div',{staticClass:"widget widget-featured-entries"},[_c('h3',{staticClass:"widget-title font-size-lg"},[_vm._v("New arrivals")]),_vm._l((_vm.products.slice(0, 4)),function(product,index){return _c('div',{key:'arrivals' + index,staticClass:"media"},[_c('router-link',{staticClass:"featured-entry-thumb",attrs:{"to":{
                name: 'shopsingle',
                params: {
                  category_slug: product.category_slug,
                  slug: product.product_slug
                }
              }}},[_c('img',{staticStyle:{"width":"64px"},attrs:{"src":_vm.dukaURL + 'uploads/' + product.image,"alt":product.product_title}})]),_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"featured-entry-title"},[_c('router-link',{attrs:{"to":{
                    name: 'shopsingle',
                    params: {
                      category_slug: product.category_slug,
                      slug: product.product_slug
                    }
                  }}},[_vm._v(_vm._s(product.product_title))])],1),_c('p',{staticClass:"featured-entry-meta"},[_vm._v(" RWF "+_vm._s(_vm.salePrice(product.orginal_price, product.retail_price))+" ")])])],1)})],2)]),_c('div',{staticClass:"col-md-4 col-sm-6 mb-1"},[_c('div',{staticClass:"widget widget-featured-entries"},[_c('h3',{staticClass:"widget-title font-size-lg"},[_vm._v("Suggested")]),_vm._l((_vm.products.slice(0, 4)),function(product,index){return _c('div',{key:'suggested' + index,staticClass:"media"},[_c('router-link',{staticClass:"featured-entry-thumb",attrs:{"to":{
                name: 'shopsingle',
                params: {
                  category_slug: product.category_slug,
                  slug: product.product_slug
                }
              }}},[_c('img',{staticStyle:{"width":"64px"},attrs:{"src":_vm.dukaURL + 'uploads/' + product.image,"alt":product.product_title}})]),_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"featured-entry-title"},[_c('router-link',{attrs:{"to":{
                    name: 'shopsingle',
                    params: {
                      category_slug: product.category_slug,
                      slug: product.product_slug
                    }
                  }}},[_vm._v(_vm._s(product.product_title))])],1),_c('p',{staticClass:"featured-entry-meta"},[_vm._v(" RWF "+_vm._s(_vm.salePrice(product.orginal_price, product.retail_price))+" ")])])],1)})],2)]),_c('div',{staticClass:"col-md-4 col-sm-6 d-block d-sm-none d-md-block mb-3"},[_c('router-link',{staticClass:"d-block text-decoration-0 mx-auto",staticStyle:{"max-width":"24rem"},attrs:{"to":{ name: 'shop' }}},[_c('div',{staticClass:"bg-secondary"},[_c('div',{staticClass:"px-3 pt-4 text-center"},[_c('h4',{staticClass:"font-size-sm font-weight-normal pt-1 mb-2"},[_vm._v(" 2020 Spring / Summer ")]),_c('h4',{staticClass:"h5 pb-2"},[_vm._v("Hoodie Collection")]),_c('div',{staticClass:"btn btn-primary btn-sm"},[_vm._v("Shop now")])]),_c('img',{attrs:{"src":_vm.dukaURL + 'assets/banner.png',"alt":"Promo banner"}})])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 py-4"},[_c('h4',{staticClass:"text-left"},[_vm._v("Top Categories")]),_c('hr')])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center py-2"},[_c('h4',{staticClass:"my-3"},[_vm._v("Featured products")]),_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br')])}]

export { render, staticRenderFns }