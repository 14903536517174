<template>
  <div class="home-send-money taller-wrapper">
    <div class="container mb-2">
      <div class="row pt-3 pb-5">
        <div class="col-md-5 mx-auto">
          <form @submit.prevent="resetPassword" class="card jb-card">
            <div class="card-body">
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
                v-if="loginSucceed"
              >
                <strong>Success!</strong> Password changed. Redirecting...
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="alert alert-danger" v-if="invalidLogin">
                <span
                  >Invalid token provided. Please use the link we sent you in
                  email to change your password</span
                >
              </div>
              <h4 class="margin-bottom-1x text-center">Reset your Password</h4>
              <div class="form-group">
                <input
                  class="form-control rounded g-px-20 g-py-12"
                  type="password"
                  v-validate="'required'"
                  name="password"
                  :class="{ 'is-invalid': errors.has('password') }"
                  placeholder="New Password"
                  ref="password"
                  v-model="credentials.password"
                />

                <div class="invalid-feedback">
                  {{ errors.first("password") }}
                </div>
              </div>

              <div class="form-group">
                <input
                  class="form-control rounded g-px-20 g-py-12"
                  type="password"
                  v-validate="'required|confirmed:password'"
                  name="Password Confirmation"
                  :class="{ 'is-invalid': errors.has('Password Confirmation') }"
                  data-vv-as="password"
                  placeholder="Confirm Password"
                />

                <div class="invalid-feedback">
                  {{ errors.first("Password Confirmation") }}
                </div>
              </div>
              <div class="text-center text-sm-right">
                <button
                  type="submit"
                  class="btn btn-block btn-filled form-btn my-2"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordChange",
  data() {
    return {
      credentials: {
        change_code: null,
        password: null
      },
      invalidLogin: false,
      loginSucceed: false
    };
  },
  methods: {
    resetPassword() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.credentials.change_code = this.$route.params.code;
          let url = "ap/change-password";
          var formData = this.formData(this.credentials);
          let DispatchpParams = { formData: formData, url: url };
          this.$store
            .dispatch("dukaPostRequest", DispatchpParams)
            .then(response => {
              if (!response.data.error) {
                this.loginSucceed = true;
                this.$router.push({
                  name: "login"
                });
              } else {
                this.invalidLogin = true;
              }
            })
            .catch(err => console.log(err));
        }
      });
    }
  }
};
</script>
