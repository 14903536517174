<template>
  <div class="section home-send-money taller-wrapper">
    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card jb-card">
            <div class="card-body">
              <form-wizard
                @on-complete="confirmPayment"
                color="#041e42"
                shape="tab"
                :title="formTitle"
                :subtitle="formSubtitle"
                ref="transferForm"
              >
                <tab-content
                  title="Amount"
                  icon="mdi mdi-cash-multiple"
                  :before-change="amountExist"
                >
                  <h4>How much would you like to transfer?</h4>
                  <div class="form-group">
                    <label for>You want to send</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">USD</span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="0.0"
                        v-model="newPayment.amount"
                      />
                    </div>
                  </div>
                </tab-content>
                <tab-content
                  title="Recipient"
                  icon="mdi mdi-account-multiple"
                  :before-change="validateReceiver"
                >
                  <template>
                    <div class="form-group">
                      <label>Account holder Firstname</label>
                      <input
                        type="text"
                        v-model="newPayment.receiver_firstname"
                        class="form-control"
                        placeholder="Receiver firstname"
                      />
                    </div>
                    <div class="form-group">
                      <label>Account holder Lastname</label>
                      <input
                        type="text"
                        v-model="newPayment.receiver_lastname"
                        class="form-control"
                        placeholder="Receiver Lastname"
                      />
                    </div>
                    <div class="form-group">
                      <label for>Email address</label>
                      <input
                        type="text"
                        v-model="newPayment.receiver_email"
                        class="form-control"
                        placeholder="Email address"
                      />
                    </div>
                    <div class="form-group">
                      <label>Phone number</label>
                      <input
                        type="text"
                        v-model="newPayment.receiver_phone"
                        class="form-control"
                        placeholder="Contact number"
                      />
                    </div>
                  </template>

                  <p>
                    Make sure they respond to our email or the money will be
                    refunded to you.
                  </p>
                </tab-content>
                <tab-content
                  title="Review and Payment"
                  icon="mdi mdi-credit-card-multiple"
                >
                  <h4>Check out summary</h4>
                  <div
                    class="card"
                    style="box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);"
                  >
                    <div class="card-body">
                      <h5>
                        Total Amount to send
                        <span class="text-primary float-right"
                          >USD {{ newPayment.amount }}</span
                        >
                      </h5>
                      <h5>
                        Total Amount to be recieved
                        <span class="text-primary float-right"
                          >USD {{ newPayment.amount }}</span
                        >
                      </h5>
                    </div>
                  </div>
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div class="wizard-footer-left">
                    <wizard-button
                      v-if="props.activeTabIndex > 0 && !props.isLastStep"
                      @click.native="props.prevTab()"
                      :style="props.fillButtonStyle"
                      >Previous</wizard-button
                    >
                  </div>
                  <div class="wizard-footer-right">
                    <wizard-button
                      v-if="props.activeTabIndex == 1"
                      @click.native="handleReceiverSkipped"
                      class="wizard-footer-right btn-skip"
                      :style="props.fillButtonStyle"
                      >Skip</wizard-button
                    >
                    <wizard-button
                      v-if="!props.isLastStep"
                      @click.native="props.nextTab()"
                      class="wizard-footer-right"
                      :style="props.fillButtonStyle"
                      >Next</wizard-button
                    >

                    <wizard-button
                      v-else
                      @click.native="confirmPayment"
                      class="wizard-footer-right finish-button"
                      :style="props.fillButtonStyle"
                    >
                      {{
                        props.isLastStep ? "Finish and Pay" : "Next"
                      }}</wizard-button
                    >
                  </div>
                </template>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="afrip-card">
      <br />

      <form
        action="https://afripay.africa/checkout/index.php"
        method="post"
        id="afripayform"
      >
        <input type="hidden" name="amount" v-model="newPayment.amount" value />

        <input type="hidden" name="currency" value="USD" />

        <input type="hidden" name="comment" value="Transfering Money" />

        <input type="hidden" name="client_token" id="clientToken" />
        <input type="hidden" name="return_url" id="returnURL" value="" />

        <input
          type="hidden"
          name="firstname"
          v-model="sender.firstname"
          value
        />

        <input type="hidden" name="lastname" v-model="sender.lastname" value />

        <input type="hidden" name="street" value="Test" />
        <input type="hidden" name="city" value="Test" />
        <input type="hidden" name="state" value="Test" />
        <input type="hidden" name="zip_code" value="Test" />

        <input type="hidden" name="country" v-model="sender.country" value />
        <input type="hidden" name="email" v-model="sender.email" value />
        <input type="hidden" name="phone" v-model="sender.phone" value />
        <input
          type="hidden"
          name="app_id"
          value="7116db164d6c4522e02154b01ddae64d"
        />

        <input type="hidden" name="app_secret" value="JDJ5JDEwJFQ3b0k2" />
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "SendMoney",
  data: () => ({
    sender: {},
    receiver: {},
    search_id: null,
    invalidTallerId: false,
    newPayment: {
      currentUser: false,
      receiver_id: null,
      amount: null,
      vat: null,
      charges: null,
      receiver_firstname: null,
      receiver_lastname: null,
      reciever_email: null,
      receiver_phone: null,
      total_amount: null
    },
    isReceiverSkipped: false
  }),
  created() {
    this.newPayment.amount = this.$route.query["amount"] || null;
    this.getCurrentSender();
  },
  methods: {
    amountExist() {
      return new Promise((resolve, reject) => {
        if (Number(this.newPayment.amount)) resolve(true);
        else reject("errors");
      });
    },
    validateReceiver() {
      return new Promise((resolve, reject) => {
        if (
          this.newPayment.receiver_firstname &&
          this.newPayment.receiver_email
        )
          resolve(true);
        else if (this.isReceiverSkipped) resolve(true);
        else reject("errors");
      });
    },
    handleReceiverSkipped() {
      this.isReceiverSkipped = true;
      this.$refs.transferForm.nextTab();
    },
    getCurrentSender() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-current-user")
        .then(response => {
          this.sender = {
            ...response.data.user_details,
            ...response.data.user
          };
        });
    },
    searchReceiver() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "customer/search-receiver/" + this.search_id
        )
        .then(response => {
          if (response.data.receiver) {
            this.receiver = response.data.receiver;
            this.invalidTallerId = false;
          } else {
            this.receiver = {};
            this.invalidTallerId = true;
          }
        });
    },
    initializeRecipient() {
      let formData = this.formData(this.newPayment);
      let url = "customer/initialize-reciever";
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (response.data.receiver_id) {
            this.newPayment.receiver_id = response.data.receiver_id;
            this.confirmPayment();
          }
        });
    },
    confirmPayment() {
      if (this.isReceiverSkipped) {
        this.newPayment.receiver_firstname = "Talar";
        let DispatchpParams = {
          formData: this.formData(this.newPayment),
          url: "customer/save-transfer"
        };
        this.$store
          .dispatch("dukaPostRequest", DispatchpParams)
          .then(response => {
            if (!response.data.error) {
              document.getElementById("returnURL").value =
                "https://talar.rw?order-code=" +
                response.data.transfer.transfer_code;
              document.getElementById("clientToken").value =
                response.data.transfer.transfer_code + "-transfer";
              document.getElementById("afripayform").submit();
            }
          });
      } else {
        if (this.newPayment.receiver_id) {
          let formData = this.formData(this.newPayment);
          let url = "customer/save-transfer";
          let DispatchpParams = { formData: formData, url: url };
          this.$store
            .dispatch("dukaPostRequest", DispatchpParams)
            .then(response => {
              if (!response.data.error) {
                document.getElementById("returnURL").value =
                  "https://talar.rw?order-code=" +
                  response.data.transfer.transfer_code;
                document.getElementById("clientToken").value =
                  response.data.transfer.transfer_code + "-transfer";
                document.getElementById("afripayform").submit();
              }
            });
        } else {
          this.initializeRecipient();
        }
      }
    },
    selectReciever(receiver) {
      this.newPayment.receiver_firstname = receiver.firstname;
      this.newPayment.receiver_lastname = receiver.lastname;
      this.newPayment.receiver_email = receiver.email;
      this.newPayment.receiver_phone = receiver.phone;
      this.newPayment.receiver_id = receiver.user_id;
    }
  }
};
</script>
<style lang="scss">
.duka-pricing h1 {
  color: hsla(215, 5%, 10%, 1);
  margin-bottom: 2rem;
}

.duka-pricing input[type="radio"] {
  display: none;
  &:not(:disabled) ~ label {
    cursor: pointer;
  }
  &:disabled ~ label {
    color: hsla(150, 5%, 75%, 1);
    border-color: hsla(150, 5%, 75%, 1);
    box-shadow: none;
    cursor: not-allowed;
  }
}
.duka-pricing label {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
  border-radius: 5px;
  padding: 0.14rem;
  margin-bottom: 1rem;
  //margin: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
  position: relative;
}
.duka-pricing input[type="radio"]:checked + label {
  &::after {
    color: hsla(215, 5%, 25%, 1);
    font: normal normal normal 24px/1 "Material Design Icons";
    border: 2px solid hsla(150, 75%, 45%, 1);
    content: "\F134";
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
  }
}
.duka-pricing input[type="radio"]#control_05:checked + label {
  background: red;
  border-color: red;
}
.duka-pricing p {
  font-weight: 900;
}
@media only screen and (max-width: 700px) {
  .duka-pricing section {
    flex-direction: column;
  }
}
</style>
