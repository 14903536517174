<template>
  <div class="container pt-5">
    <div class="row pb-4">
      <div class="col-lg-9 mx-auto">
        <template v-if="stepMode.customerDetails">
          <div
            class="d-sm-flex justify-content-between bg-secondary px-4 py-3 mb-4"
          >
            <div class="media">
              <img
                class="img-thumbnail rounded-circle mr-3"
                :src="dukaURL + 'assets/avatar.jpg'"
                style="width:95px"
                :alt="customer.firstname"
              />
              <div class="media-body align-self-center">
                <h6 class="mb-1">
                  {{ customer.firstname }} {{ customer.lastname }}
                </h6>
                <div class="font-size-sm">
                  <span class="text-warning">{{ customer.email }}</span>
                </div>
                <div class="font-size-sm opacity-75">
                  <span class="mr-1">Tel:</span>
                  <span class="font-weight-semibold">{{ customer.phone }}</span>
                </div>
              </div>
            </div>
          </div>
          <h6 class="mb-3 pt-2 pb-3 border-bottom">Shipping adress</h6>
          <form data-vv-scope="form1">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-fn">Personal or company name</label>
                  <input
                    class="form-control"
                    type="text"
                    id="checkout-fn"
                    v-model="newShipping.firstname"
                    name="Name"
                    v-validate="'required'"
                    placeholder="Your Name"
                    :class="{ 'is-invalid': errors.has('form1.Name') }"
                  />
                  <span class="text-danger">{{
                    errors.first("form1.Name")
                  }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-country">Country</label>
                  <country-select
                    v-model="newShipping.country"
                    :country="newShipping.country"
                    placeholder="Select Country"
                    class="form-control custom-select"
                    topCountry="US"
                    name="Country"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('form1.Country') }"
                  />
                  <span class="text-danger">{{
                    errors.first("form1.Country")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-email">E-mail Address</label>
                  <input
                    class="form-control"
                    type="email"
                    id="checkout-email"
                    v-model="newShipping.email"
                    name="Email"
                    placeholder="Your Email"
                    v-validate="'required|email'"
                    :class="{ 'is-invalid': errors.has('form1.Email') }"
                  />
                  <span class="text-danger">{{
                    errors.first("form1.Email")
                  }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-phone">Phone Number</label>
                  <input
                    class="form-control"
                    type="text"
                    id="checkout-phone"
                    v-model="newShipping.phone"
                    name="Phone number"
                    v-validate="'required'"
                    placeholder="Your Phone number"
                    :class="{ 'is-invalid': errors.has('form1.Phone number') }"
                  />
                  <span class="text-danger">{{
                    errors.first("form1.Phone number")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-city">City/state/Province</label>
                  <input
                    class="form-control"
                    type="text"
                    id="checkout-city"
                    v-model="newShipping.city"
                    name="City"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('form1.City') }"
                    placeholder="City or State"
                  />
                  <span class="text-danger">{{
                    errors.first("form1.City")
                  }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-zip">ZIP Code</label>
                  <input
                    class="form-control"
                    type="text"
                    id="checkout-zip"
                    v-model="newShipping.zip_code"
                    placeholder="Zip Code"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-address-1">Address 1</label>
                  <input
                    class="form-control"
                    type="text"
                    id="checkout-address-1"
                    v-model="newShipping.address_one"
                    name="Address"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('form1.Address') }"
                    placeholder="Primary address"
                  />
                  <span class="text-danger">{{
                    errors.first("form1.Address")
                  }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-address-2">Address 2</label>
                  <input
                    class="form-control"
                    type="text"
                    id="checkout-address-2"
                    v-model="newShipping.address_two"
                    placeholder="Any Other address"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- Navigation-->
          <div class="d-flex pt-4 mt-3">
            <div class="w-50 pr-3">
              <router-link
                class="btn btn-outline-secondary btn-block"
                :to="{ name: 'shoppingcart' }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevron-left mr-1"
                >
                  <polyline points="15 18 9 12 15 6" />
                </svg>
                <span class="d-none d-sm-inline">Back to cart</span>
                <span class="d-inline d-sm-none">Back</span>
              </router-link>
            </div>
            <div class="w-50 pl-2">
              <a
                class="btn btn-primary btn-block"
                href="javascript:void(0)"
                @click.prevent="proceedOrder"
              >
                <span class="d-none d-sm-inline">Complete Order</span>
                <span class="d-inline d-sm-none">Finish</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevron-right ml-1"
                >
                  <polyline points="9 18 15 12 9 6" />
                </svg>
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div id="afrip-card" style="display:none">
      <form
        action="https://afripay.africa/checkout/index.php"
        method="post"
        id="afripayform"
      >
        <input type="hidden" name="amount" v-model="checkoutTotal" />
        <input type="hidden" name="currency" value="RWF" />
        <input
          type="hidden"
          name="comment"
          value="Paying for a shopping cart"
        />
        <input type="hidden" name="client_token" id="clientToken" />
        <input type="hidden" name="return_url" id="returnURL" value="" />
        <input type="hidden" name="firstname" v-model="customer.firstname" />
        <input type="hidden" name="lastname" v-model="customer.lastname" />
        <input type="hidden" name="street" v-model="newShipping.address_one" />
        <input type="hidden" name="city" v-model="newShipping.address_one" />
        <input type="hidden" name="state" v-model="newShipping.country" />
        <input type="hidden" name="zip_code" v-model="newShipping.zip_code" />
        <input type="hidden" name="country" v-model="customer.country" />
        <input type="hidden" name="email" v-model="customer.email" />
        <input type="hidden" name="phone" v-model="customer.phone" />
        <input
          type="hidden"
          name="app_id"
          value="7116db164d6c4522e02154b01ddae64d"
        />
        <input type="hidden" name="app_secret" value="JDJ5JDEwJFQ3b0k2" />
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckOutProcess",
  data: () => ({
    newShipping: {
      firstname: null,
      lastname: null,
      country: null,
      zip_code: null,
      address_one: null,
      address_two: null,
      email: null,
      phone: null,
      company: null,
      city: null
    },
    customer: {},
    stepMode: {
      customerDetails: true,
      finalReview: false,
      payment: false
    },
    checkoutTotal: 0,
    grandTotal: 0
  }),

  created() {
    this.getCurrentCustomer();
    this.cartProducts = this.parseItems();
    this.calculateDiscount();
    if (!this.cartProducts.length) window.location.replace("/");
  },
  methods: {
    getCurrentCustomer() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-current-user/addresses")
        .then(response => {
          this.customer = {
            ...response.data.user_details,
            ...response.data.user
          };
          this.addresses = response.data.addresses;
          if (this.addresses.length) {
            this.selectAddress(this.addresses[0]);
          } else {
            this.newShipping.firstname =
              this.customer.firstname + " " + this.customer.lastname;
            this.newShipping.email = this.customer.email;
            this.newShipping.phone = this.customer.phone;
            this.newShipping.country = this.customer.country;
          }
        });
    },
    selectAddress(address) {
      this.newShipping = { ...address };
    },
    proceedOrder() {
      this.$validator.validateAll("form1").then(valid => {
        if (valid) {
          let url = "market/proceed-order";
          let newForm = {
            cartItems: this.cartItems
          };
          var formData = this.formData({ ...newForm, ...this.newShipping });
          let DispatchpParams = { formData: formData, url: url };
          this.$store
            .dispatch("dukaPostRequest", DispatchpParams)
            .then(response => {
              this.$store.dispatch("clearCart").then(() => {
                this.$root.$emit("myEvent", this.afteraCart());
                if (response.data.order_code) {
                  document.getElementById("returnURL").value =
                    "https://boyo.publinet.rw/checkout-complete?order-code=" +
                    response.data.order_code;
                  document.getElementById("clientToken").value =
                    response.data.order_code + "-product_order";
                  document.getElementById("afripayform").submit();
                }
              });
            });
        }
      });
    },
    calculateDiscount() {
      let cartProducts = this.parseItems();
      let grandTotal = 0;
      cartProducts.forEach(product => {
        grandTotal += Number(this.getCartDiscount(product).replace(/,/g, ""));
      });
      this.grandTotal = grandTotal;
      this.checkoutTotal = grandTotal;
    }
  }
};
</script>
