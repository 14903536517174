<template>
  <div class="section home-send-money taller-wrapper">
    <div class="container mb-2">
      <div class="row mt-2">
        <template v-if="beforeValidating">
          <div
            class="col-md-6 text-center mx-auto my-auto"
            style="padding-bottom:70px;"
          >
            <div class="card jb-card" style="margin-top:20px;" v-if="timedout">
              <div class="card-body">
                <p class="text-center">
                  <img
                    :src="dukaURL + 'assets/sent.png'"
                    style="width:120px; height:120px; margin:0 auto;"
                    class="img img-responsive"
                  />
                  Your request can't be completed right now due to the server
                  timeout. Please check your internet connection and
                  <a href="#">Try again</a>
                  <br />You can also refresh/reload your browser to fix this
                  issue.
                </p>
              </div>
            </div>
            <div class="card jb-card" v-else>
              <div class="card-body">
                <div
                  class="alert alert-success alert-dismissible fade show"
                  role="alert"
                  v-if="verified"
                >
                  <strong>Success!</strong> Password changed.
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="alert alert-danger" v-if="invalidEmail">
                  <span
                    >Your email does not exist in our system. Try again</span
                  >
                </div>
                <h5>
                  Recover your account password
                  <br />
                  <small
                    >In order to reset your account password, you need to
                    provide your email address</small
                  >
                </h5>
                <div class="form-group text-right">
                  <input
                    type="text"
                    name="UserEmail"
                    v-validate="'required|email'"
                    v-model="UserInput.UserEmail"
                    class="form-control text-left"
                    :class="{ 'is-invalid': errors.has('UserEmail') }"
                    placeholder="Type your email here"
                  />
                  <span class="text-danger" id="error-code"></span>
                </div>
                <div class="mt-3">
                  <button
                    class="btn btn-block btn-filled form-btn my-2"
                    type="button"
                    @click="validateEmail"
                    :disabled="!UserInput.UserEmail"
                  >
                    <span
                      class="bh"
                      style="top: 59.125px; left: 95.5px;"
                    ></span>
                    <span>
                      Reset your password
                      <i class="dripicons-arrow-thin-right float-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-md-6 mx-auto">
            <div class="card">
              <div class="card-body">
                <h3>
                  Password resetting link has been sent to your email address.
                  <br />
                  <small
                    >You can access your email address to reset your password
                    now</small
                  >
                </h3>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  props: {
    msg: String
  },
  data() {
    return {
      authenticated: false,
      beforeValidating: true,
      verified: false,
      invalidEmail: false,
      timedout: false,
      UserInput: {
        UserEmail: null
      }
    };
  },
  methods: {
    validateEmail() {
      let url = "ap/email-check";
      var formData = this.formData(this.UserInput);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (response.data.error) {
            if (response.data.email_fail) this.timedout = true;
            else this.invalidEmail = true;
          } else {
            this.verified = true;
            this.beforeValidating = false;
          }
        });
    }
  }
};
</script>
