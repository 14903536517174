<template>
  <div class="taller-wrapper">
    <section
      class="hero-slider py-5"
      v-if="heroBanners.length"
      :style="{
        'background-image':
          'url(' + dukaURL + 'uploads/hero-slider/main-bg.jpg)'
      }"
    >
      <carousel
        :nav="true"
        :dots="true"
        :loop="true"
        :autoplay="true"
        :autoplayTimeout="7000"
        :items="1"
      >
        <div
          class="container padding-top-3x"
          v-for="(banner, index) in heroBanners"
          :key="'banner' + index"
        >
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-5 col-md-6 text-md-left text-center">
              <div class="from-bottom">
                <div class="h2 text-body text-normal mb-2">
                  {{ banner.banner_title }}
                </div>
                <div class="h2 text-body text-normal mb-4 pb-1">
                  <span class="text-bold">{{ banner.banner_subtitle }}</span>
                </div>
              </div>
              <router-link
                class="btn btn-primary scale-up delay-1"
                :to="{ name: 'shop' }"
                >{{ banner.url_label }}</router-link
              >
            </div>
            <div class="col-md-5 pb-2">
              <img
                class="d-block mx-auto"
                :src="dukaURL + 'uploads/home-slide/' + banner.image"
                :alt="banner.banner_title"
              />
            </div>
          </div>
        </div>
      </carousel>
    </section>
    <section class="py-3" v-if="topCategories.length">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 py-4">
            <h4 class="text-left">Top Categories</h4>
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 cat_slider cat_style1 mt-4 mt-md-0">
            <carousel
              :loop="true"
              :dots="false"
              :nav="false"
              :margin="30"
              :responsive="{
                0: { items: 2 },
                480: { items: 3 },
                576: { items: 4 },
                768: { items: 5 },
                991: { items: 6 },
                1199: { items: 7 }
              }"
            >
              <div
                class="categories_box"
                v-for="(category, index) in topCategories"
                :key="'topcategory' + index"
              >
                <a href="javascript:void(0)" class="">
                  <img
                    :src="`${dukaURL}uploads/categories/${category.image}`"
                    alt="cat_img1"
                  />
                  <span>{{ category.category_name }}</span>
                </a>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-0 px-3 pb-5" style="background-color: #fcfcfc;">
      <div class="container">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center py-2"
        >
          <h4 class="my-3">Featured products</h4>
          <hr />
        </div>

        <div class="row">
          <div
            class="col-lg-3 col-sm-4 col-12 mb-3"
            v-for="(product, index) in products"
            :key="'products' + index"
          >
            <div class="product-card">
              <div class="product-thumb">
                <router-link
                  :to="{
                    name: 'shopsingle',
                    params: {
                      category_slug: product.category_slug,
                      slug: product.product_slug
                    }
                  }"
                >
                  <img
                    :src="dukaURL + 'uploads/' + product.image"
                    :alt="product.product_title"
                  />
                </router-link>
              </div>
              <div class="product-card-body text-center">
                <router-link
                  :to="{
                    name: 'shop',
                    params: { slug: product.category_slug }
                  }"
                  class="product-meta"
                  >{{ product.category_name }}</router-link
                >
                <h3 class="product-card-title two-line">
                  <router-link
                    :to="{
                      name: 'shopsingle',
                      params: {
                        category_slug: product.category_slug,
                        slug: product.product_slug
                      }
                    }"
                    >{{ product.product_title }}</router-link
                  >
                </h3>
                <h6 class="text-danger font-weight-light mb-0">
                  RWF
                  {{ salePrice(product.orginal_price, product.retail_price) }}
                </h6>
                <h6 class="text-dark font-weight-light">
                  <template v-if="Number(product.retail_price)">
                    <s>RWF {{ addComma(product.orginal_price) }}</s> &nbsp;
                    <span class="text-primary">
                      {{ product.retail_price }}% off
                    </span>
                  </template>
                  <template v-else>
                    <div>
                      <br />
                    </div>
                  </template>
                </h6>
                <div class="row">
                  <div class="col-6 px-1">
                    <button
                      class="btn btn-small btn-block btn-primary"
                      @click="addToCart(product)"
                    >
                      Add to cart
                    </button>
                  </div>
                  <div class="col-6 px-1">
                    <button
                      class="btn btn-small btn-secondary btn-block"
                      type="button"
                      @click="buyNow(product)"
                    >
                      Buy now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container py-4 pb-md-5">
      <div class="row">
        <div class="col-md-4 col-sm-6 mb-1">
          <div class="widget widget-featured-entries">
            <h3 class="widget-title font-size-lg">New arrivals</h3>
            <div
              class="media"
              v-for="(product, index) in products.slice(0, 4)"
              :key="'arrivals' + index"
            >
              <router-link
                :to="{
                  name: 'shopsingle',
                  params: {
                    category_slug: product.category_slug,
                    slug: product.product_slug
                  }
                }"
                class="featured-entry-thumb"
              >
                <img
                  :src="dukaURL + 'uploads/' + product.image"
                  :alt="product.product_title"
                  style="width:64px"
              /></router-link>
              <div class="media-body">
                <h6 class="featured-entry-title">
                  <router-link
                    :to="{
                      name: 'shopsingle',
                      params: {
                        category_slug: product.category_slug,
                        slug: product.product_slug
                      }
                    }"
                    >{{ product.product_title }}</router-link
                  >
                </h6>
                <p class="featured-entry-meta">
                  RWF
                  {{ salePrice(product.orginal_price, product.retail_price) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-1">
          <div class="widget widget-featured-entries">
            <h3 class="widget-title font-size-lg">Suggested</h3>
            <div
              class="media"
              v-for="(product, index) in products.slice(0, 4)"
              :key="'suggested' + index"
            >
              <router-link
                :to="{
                  name: 'shopsingle',
                  params: {
                    category_slug: product.category_slug,
                    slug: product.product_slug
                  }
                }"
                class="featured-entry-thumb"
              >
                <img
                  :src="dukaURL + 'uploads/' + product.image"
                  :alt="product.product_title"
                  style="width:64px"
              /></router-link>
              <div class="media-body">
                <h6 class="featured-entry-title">
                  <router-link
                    :to="{
                      name: 'shopsingle',
                      params: {
                        category_slug: product.category_slug,
                        slug: product.product_slug
                      }
                    }"
                    >{{ product.product_title }}</router-link
                  >
                </h6>
                <p class="featured-entry-meta">
                  RWF
                  {{ salePrice(product.orginal_price, product.retail_price) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 d-block d-sm-none d-md-block mb-3">
          <router-link
            class="d-block text-decoration-0 mx-auto"
            :to="{ name: 'shop' }"
            style="max-width: 24rem;"
          >
            <div class="bg-secondary">
              <div class="px-3 pt-4 text-center">
                <h4 class="font-size-sm font-weight-normal pt-1 mb-2">
                  2020 Spring / Summer
                </h4>
                <h4 class="h5 pb-2">Hoodie Collection</h4>
                <div class="btn btn-primary btn-sm">Shop now</div>
              </div>
              <img
                :src="dukaURL + 'assets/banner.png'"
                alt="Promo banner"
              /></div
          ></router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ShopLanding",
  data: () => ({
    products: [],
    currentOptions: {},
    newCart: {
      quantity: 1
    },
    heroBanners: [],
    categories: [],
    topCategories: []
  }),
  created() {
    this.getLandindData();
  },
  updated() {
    this.$nextTick(() => {
      document.getElementsByClassName("owl-next")[0].innerHTML =
        '<i class="dripicons-chevron-right"></i>';
      document.getElementsByClassName("owl-prev")[0].innerHTML =
        '<i class="dripicons-chevron-left"></i>';
    });
    document.querySelectorAll("div.filter").forEach(elt => {
      elt.addEventListener("click", () => {
        elt.classList.toggle("active-filter");
      });
    });
  },
  computed: {
    filteredProducts: function() {
      let filterKey = this.filterKey && this.filterKey.toLowerCase();
      let records = this.records;
      if (filterKey) {
        records = this.products;
        records = records.filter(row => {
          return Object.keys(row).some(key => {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          });
        });
        this.generatePagination(records);
      }
      if (this.selectedBrands.length) {
        records = this.products;
        records = records.filter(row => {
          return this.selectedBrands.includes(row.brand_id);
        });
        this.generatePagination(records);
      }
      return records;
    }
  },
  methods: {
    toggleCategories() {
      document
        .getElementById("categories-sidebar")
        .classList.toggle("categories-open");
    },
    getLandindData() {
      this.$store
        .dispatch("dukaGetRequest", "market/get-landing-contents")
        .then(response => {
          this.products = response.data.products;
          this.heroBanners = response.data.banners;
          this.topCategories = response.data.topCategories;
        });
    },
    addToCart(product, loan_request = false) {
      if (this.newCart.quantity) {
        this.qtyError = false;
        const successText =
          loan_request === true
            ? "Added successfully to loan cart"
            : "Product added succcessfully in shopping cart";
        let cart = {
          row_id: this.uuidv4(),
          product_id: product.master_id,
          price: product.orginal_price,
          retail_percentage: product.retail_perc || 0,
          retail_price: product.retail_price || 0,
          product_title: product.product_title,
          product_image: product.image,
          qty: this.newCart.quantity,
          ref_key: product.user_id,
          options: this.currentOptions,
          isLoan: loan_request
        };
        this.$store.dispatch("cartRequest", cart).then(() => {
          if (loan_request === true) {
            this.$root.$emit("newLoanItemsListner", this.afteraCart(true));
            this.$notify({
              group: "foo",
              title: "Loan cart",
              type: "warning",
              text: successText
            });
            this.product.loan.calculator = this.loanCalculator;
            this.$store.dispatch("currentLoanRequest", this.product.loan);
          } else {
            this.$root.$emit("newCartItemsListner", this.afteraCart());
            this.$notify({
              group: "foo",
              title: "Shopping cart",
              type: "success",
              text: successText
            });
          }
        });
      } else {
        this.qtyError = true;
      }
    },

    buyNow(product) {
      this.$store.dispatch("clearCart").then(() => {
        let cart = {
          row_id: this.uuidv4(),
          product_id: product.master_id,
          price: product.orginal_price,
          retail_percentage: product.retail_perc || 0,
          retail_price: product.retail_price || 0,
          product_title: product.product_title,
          product_image: product.image,
          qty: this.newCart.quantity,
          ref_key: product.user_id,
          options: this.currentOptions,
          isLoan: false
        };
        this.$store.dispatch("cartRequest", cart).then(() => {
          this.$root.$emit("newCartItemsListner", this.afteraCart());
          this.$router.push({ name: "shoppingcart" });
        });
      });
    }
  }
};
</script>
<style lang="css">
.categories_box {
  text-align: center;
}
.categories_box span {
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
}
.cat_style1 .categories_box span {
  margin-top: 10px;
}
</style>
