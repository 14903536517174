<template>
  <div class="section home-send-money taller-wrapper">
    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card jb-card">
            <form
              @submit.prevent="logUser"
              autocomplete="off"
              class="card-body"
            >
              <div class="mt-3">
                <div class="alert alert-danger" v-if="invalidLogin">
                  <span>Invalid password or username</span>
                </div>
                <div
                  v-if="billingRequest || isCheckOutRequest"
                  class="alert alert-success"
                >
                  You have to log into Account before proceeding your order
                </div>
                <div v-if="isOfferRequest" class="alert alert-success">
                  You have to log into Account before proceeding with offer
                  payment
                </div>
                <div v-if="isTransferRequest" class="alert alert-success">
                  You have to log into Account to send money
                </div>
                <h4 class="mb-2 font-weight-bold text-dark">
                  Log into account
                </h4>
                <hr />
                <div class="form-group">
                  <input
                    class="form-control"
                    name="Username"
                    v-validate="'required'"
                    type="text"
                    v-model="credentials.username"
                    placeholder="Email address"
                    :class="{ 'is-invalid': errors.has('Username') }"
                  />
                  <div class="invalid-feedback">
                    {{ errors.first("Username") }}
                  </div>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="password"
                    v-validate="'required'"
                    name="password"
                    :class="{ 'is-invalid': errors.has('password') }"
                    placeholder="Your Password"
                    ref="password"
                    v-model="credentials.password"
                  />

                  <div class="invalid-feedback">
                    {{ errors.first("password") }}
                  </div>
                </div>
                <div
                  class="d-flex flex-wrap justify-content-between padding-bottom-1x"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      id="remember_me"
                      checked
                    />
                    <label class="custom-control-label" for="remember_me"
                      >Remember me</label
                    >
                  </div>
                  <router-link :to="{ name: 'resetpassword' }"
                    >Forgot password?</router-link
                  >
                </div>
                <div class="text-center text-sm-right">
                  <button class="btn btn-block btn-filled form-btn my-2">
                    <span
                      class="bh"
                      style="top: 59.125px; left: 95.5px;"
                    ></span>
                    <span>
                      Authenticate and sign-in
                      <i class="dripicons-arrow-thin-right float-right"></i>
                    </span>
                  </button>
                </div>
                <div class="text-left">
                  Don't have Boyoro account?
                  <router-link
                    :to="{ name: 'signup' }"
                    style="text-decoration:underline"
                    >Sign up now?</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  props: {
    passwordReseted: {
      type: Boolean,
      default: false
    },
    billingRequest: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    credentials: {
      username: null,
      password: null
    },
    invalidLogin: false,
    loginSucceed: false,
    isCheckOutRequest: false,
    isOfferRequest: false,
    isTransferRequest: false
  }),
  created() {
    this.isCheckOutRequest =
      this.$route.query["origin-request"] &&
      this.$route.query["origin-request"] === "shopping-cart";
    this.isOfferRequest =
      this.$route.query["origin-request"] &&
      this.$route.query["origin-request"] === "offer-checkout";
    this.isTransferRequest =
      this.$route.query["origin-request"] &&
      this.$route.query["origin-request"] === "money-transfer";
  },
  methods: {
    logUser: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var formData = this.formData(this.credentials);
          this.$store
            .dispatch("logUser", formData)
            .then(response => {
              if (response.data.error) {
                this.invalidLogin = true;
                this.loginSucceed = false;
              } else {
                this.invalidLogin = false;
                this.loginSucceed = true;
                if (this.billingRequest || this.isCheckOutRequest)
                  window.location.replace("/shopping/check-out-process");
                else if (this.isOfferRequest)
                  window.location.replace(
                    this.$route.query["redirect-url"] +
                      "&redirect-request=payment"
                  );
                else if (this.isTransferRequest)
                  window.location.replace(this.$route.query["redirect-url"]);
                else {
                  if (response.data.user.user_type === "company")
                    window.location.replace("/account");
                  else window.location.replace("/user");
                }
              }
            })
            .catch(err => console.log(err));
        }
      });
    }
  }
};
</script>
<style scoped lang="css"></style>
