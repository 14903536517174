<template>
  <section class="mission-vision">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <template>
            <div v-html="content"></div>
          </template>
        </div>
        <div class="col-md-5 offset-md-1">
          <figure class="imgBox">
            <img :src="dukaURL + 'uploads/mission.jpg'" alt="" />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Landing",
  data: () => ({
    amount: null
  }),
  created() {
    this.loadContent("about-us");
  }
};
</script>
<style lang="css"></style>
