<template>
  <div>
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <h4>
            Search Results for: <b>{{ $route.query.q }}</b>
          </h4>
          <hr />
          <div
            class="d-flex flex-wrap justify-content-center justify-content-sm-between pb-3"
          >
            <div class="d-none d-sm-flex flex-wrap">
              <div class="form-inline flex-nowrap pb-3">
                <label class="mr-2 d-none d-sm-block" for="number">Show:</label>
                <select
                  class="form-control custom-select mr-sm-2"
                  id="number"
                  @change="changePaginationRows($event)"
                >
                  <option>12</option>
                  <option>24</option>
                  <option>48</option>
                  <option>96</option> </select
                ><small
                  class="form-text text-muted text-nowrap d-none d-sm-block"
                  >From {{ recordsLength }}
                </small>
              </div>
            </div>
            <div class="form-inline d-none d-sm-flex flex-nowrap pb-3">
              <input
                type="search"
                v-model="filterKey"
                placeholder="Search for a product"
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-4 col-sm-6 col-12 mb-3"
              v-for="(product, index) in filteredProducts"
              :key="'products' + index"
            >
              <div class="product-card mb-4">
                <div class="product-thumb">
                  <router-link
                    :to="{
                      name: 'shopsingle',
                      params: {
                        category_slug: product.category_slug,
                        slug: product.product_slug
                      }
                    }"
                  >
                    <img
                      :src="dukaURL + 'uploads/' + product.image"
                      :alt="product.product_title"
                    />
                  </router-link>
                </div>
                <div class="product-card-body text-center">
                  <router-link
                    :to="{
                      name: 'shop',
                      params: { slug: product.category_slug }
                    }"
                    class="product-meta"
                    >{{ product.category_name }}</router-link
                  >
                  <h3 class="product-card-title two-line">
                    <router-link
                      :to="{
                        name: 'shopsingle',
                        params: {
                          category_slug: product.category_slug,
                          slug: product.product_slug
                        }
                      }"
                      >{{ product.product_title }}</router-link
                    >
                  </h3>
                  <h6 class="text-danger font-weight-light mb-0">
                    RWF
                    {{ salePrice(product.orginal_price, product.retail_price) }}
                  </h6>
                  <h6 class="text-dark font-weight-light">
                    <template v-if="Number(product.retail_price)">
                      <s>RWF {{ addComma(product.orginal_price) }}</s> &nbsp;
                      <span class="text-primary">
                        {{ product.retail_price }}% off
                      </span>
                    </template>
                    <template v-else>
                      <div>
                        <br />
                      </div>
                    </template>
                  </h6>
                  <div class="row">
                    <div class="col-6 px-1">
                      <button
                        class="btn btn-small btn-block btn-primary"
                        @click="addToCart(product)"
                      >
                        Add to cart
                      </button>
                    </div>
                    <div class="col-6 px-1">
                      <button
                        class="btn btn-small btn-secondary btn-block"
                        type="button"
                        @click="buyNow(product)"
                      >
                        Buy now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="pb-4 mb-2" />
          <pagination
            :current_page="currentPage"
            :row_count_page="rowCountPage"
            @page-update="pageUpdate"
            :total_records="totalRows"
            :page_range="pageRange"
            prevIcon="ti-arrow-left"
            nextIcon="ti-arrow-right"
            dotsIcon="ti-more"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyPaginations from "@/components/Paginations.vue";
//import 'vue-range-component/dist/vue-range-slider.css'
export default {
  name: "Shop",
  components: {
    pagination: MyPaginations
    //VueRangeSlider: () => import VueRangeSlider from 'vue-range-component'
  },
  data: () => ({
    products: [],
    priceRange: [0, 100],
    selectedBrands: [],
    minPrice: 0,
    maxPrice: 100000,
    currentOptions: {},
    newCart: {
      quantity: 1
    }
  }),
  created() {
    this.rowCountPage = 6;
    this.responseRef = "products";
    let url = "market/global-search";
    var formData = this.formData({ q: this.$route.query.q });
    let DispatchpParams = { formData: formData, url: url };
    this.loadPaginationRecords(DispatchpParams);
  },
  computed: {
    filteredProducts: function() {
      let filterKey = this.filterKey && this.filterKey.toLowerCase();
      let records = this.records;
      if (filterKey) {
        records = this.dbResponse.products;
        records = records.filter(row => {
          return Object.keys(row).some(key => {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          });
        });
        this.generatePagination(records);
      }
      if (this.selectedBrands.length) {
        records = this.dbResponse.products;
        records = records.filter(row => {
          return this.selectedBrands.includes(row.brand_id);
        });
        this.generatePagination(records);
      }
      return records;
    }
  },
  updated() {
    document.querySelectorAll("div.filter").forEach(elt => {
      elt.addEventListener("click", () => {
        elt.classList.toggle("active-filter");
      });
    });
  },
  methods: {
    toggleCategories() {
      document
        .getElementById("categories-sidebar")
        .classList.toggle("categories-open");
    },
    priceFilter() {
      this.$router.push({
        query: {
          "min-price": this.priceRange[0],
          "max-price": this.priceRange[1]
        }
      });
      console.log(this.priceRange);
    },
    handleCategoryFilter(category_slug) {
      this.$router.push({
        name: "shopsinglecategory",
        params: { category_slug: category_slug }
      });
      this.paginationURL = "market/products-all/" + category_slug;
      this.loadPaginationRecords(null, true);
    },
    getLandindData() {
      this.$store
        .dispatch("dukaGetRequest", "market/shop-landing-data")
        .then(response => {
          this.products = response.data.products;
        });
    },
    addToCart(product, loan_request = false) {
      if (this.newCart.quantity) {
        this.qtyError = false;
        const successText =
          loan_request === true
            ? "Added successfully to loan cart"
            : "Product added succcessfully in shopping cart";
        let cart = {
          row_id: this.uuidv4(),
          product_id: product.master_id,
          price: product.orginal_price,
          retail_percentage: product.retail_perc || 0,
          retail_price: product.retail_price || 0,
          product_title: product.product_title,
          product_image: product.image,
          qty: this.newCart.quantity,
          ref_key: product.user_id,
          options: this.currentOptions,
          isLoan: loan_request
        };
        this.$store.dispatch("cartRequest", cart).then(() => {
          if (loan_request === true) {
            this.$root.$emit("newLoanItemsListner", this.afteraCart(true));
            this.$notify({
              group: "foo",
              title: "Loan cart",
              type: "warning",
              text: successText
            });
            this.product.loan.calculator = this.loanCalculator;
            this.$store.dispatch("currentLoanRequest", this.product.loan);
          } else {
            this.$root.$emit("newCartItemsListner", this.afteraCart());
            this.$notify({
              group: "foo",
              title: "Shopping cart",
              type: "success",
              text: successText
            });
          }
        });
      } else {
        this.qtyError = true;
      }
    },

    buyNow(product) {
      this.$store.dispatch("clearCart").then(() => {
        let cart = {
          row_id: this.uuidv4(),
          product_id: product.master_id,
          price: product.orginal_price,
          retail_percentage: product.retail_perc || 0,
          retail_price: product.retail_price || 0,
          product_title: product.product_title,
          product_image: product.image,
          qty: this.newCart.quantity,
          ref_key: product.user_id,
          options: this.currentOptions,
          isLoan: false
        };
        this.$store.dispatch("cartRequest", cart).then(() => {
          this.$root.$emit("newCartItemsListner", this.afteraCart());
          this.$router.push({ name: "shoppingcart" });
        });
      });
    }
  }
};
</script>
<style lang="css">
.app-content {
  padding: 40px 15px;
}
.vue-range-slider.slider-component .slider-tooltip-wrap .slider-tooltip {
  border: 1px solid #000 !important;
  background-color: #000 !important;
}
.vue-range-slider.slider-component .slider .slider-process {
  background-color: #000 !important;
}
.categories-open {
  left: 0 !important;
}
.filter {
  cursor: pointer;
}
.filter-options {
  display: none;
}
.active-filter .filter-options {
  display: block;
}
.active-filter i:before {
  content: "\e64b" !important;
}
</style>
