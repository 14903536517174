<template>
  <div class="section bg-secondary">
    <duka-modal
      v-if="modalOpen"
      @close="
        () => {
          return false;
        }
      "
      style="display:block"
      :modalSize="'default'"
    >
      <span slot="head" style="padding-top:10px">Activate your account</span>
      <div slot="body">
        <div class="w-100 text-center">
          <img
            :src="dukaURL + 'assets/env.png'"
            class="img img-responsive align-self-center rounded-circle mb-2"
            style="width: 120px; margin: 0 auto"
          />

          <h4>
            <b>Please confirm your email</b>
          </h4>
          <p>
            We sent a confirmation link to
            <b>{{ loggedUser.email }}.</b>
            <br />Please go to your inbox and click the confirmation link to
            continue
          </p>
          <hr />
          <p>
            Don't get an email?
            <a href="javascript:void(0)" style="text-decoration:underline"
              >Resend</a
            >
            or
            <a href="javascript:void(0)" style="text-decoration:underline"
              >Sign up with a different email</a
            >
          </p>

          <p>
            Already confirmed?
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-primary"
              @click.prevent="reloadPage"
            >
              <i class="ti-reload"></i>
              Refresh now
            </a>
          </p>
        </div>
      </div>
    </duka-modal>
    <div class="container">
      <div class="row pt-5">
        <div class="col-lg-2 mobo-hidden">
          <div class="pb-4">
            <!-- <a
              class="gallery-item mx-auto rounded-circle"
              href="javascript:void(0)"
              style="max-width: 4.75rem;"
            >
              <img :src="dukaURL + 'assets/avatar.jpg'" />
            </a>
            <div class="text-center mt-2s mb-2">
              <h6 class="mb-0">
                {{ user.lastname + " " + user.firstname }}
              </h6>
              <span class="font-size-sm text-warning">{{
                loggedUser.email
              }}</span>
            </div> -->
            <div class="show" id="account-menu" style="">
              <div class="list-group">
                <router-link
                  class="list-group-item jb-card border-bottom list-group-item-action"
                  :to="{ name: 'customerorders' }"
                  >My orders<span
                    class="badge badge-pill bg-0 border ml-2"
                    style="background-color: #041e42!important;"
                    ><span class="text-white">{{ orders }}</span></span
                  ></router-link
                ><router-link
                  class="list-group-item list-group-item-action jb-card border-bottom"
                  :to="{ name: 'usertransfers' }"
                  >Transfers<span
                    class="badge badge-pill bg-0 border ml-2"
                    style="background-color: #041e42!important;"
                    ><span class="text-white">{{ transfers }}</span></span
                  ></router-link
                ><router-link
                  class="list-group-item list-group-item-action jb-card border-bottom"
                  :to="{ name: 'userprofile' }"
                  >Profile info</router-link
                ><router-link
                  class="list-group-item list-group-item-action jb-card"
                  :to="{ name: 'useraddresses' }"
                  >Addresses</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10 pb-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "User",
  data: () => ({
    user: {},
    loggedUser: {},
    transfers: 0,
    orders: 0
  }),
  created() {
    this.loadCurrentUser();
  },
  methods: {
    loadCurrentUser() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-user-tails")
        .then(response => {
          this.resultFound = true;
          this.user = response.data.user_details;
          this.loggedUser = response.data.user;
          this.transfers = response.data.transfers;
          this.orders = response.data.orders;
          if (!Number(response.data.user.active)) {
            this.toggleModal();
            this.getRequests();
          }
        });
    }
  }
};
</script>
