<template>
  <div class="card jb-card">
    <div class="card-body">
      <h5 class="header-title pb-3 mt-0">
        List of all Transfers
        <span
          class="badge badge-pill badge-primary"
          style="background-color: #041e42!important;"
          >{{ transfers.length }}</span
        >
      </h5>
      <div class="table-responsive table-responsive-sm">
        <table class="table table-sm table-hover mb-0">
          <thead>
            <tr class="align-self-center">
              <th>Transfer code</th>
              <th>Transfer Date</th>
              <th>Reciever Name</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transfer, index) in transfers"
              :key="'transfer' + index"
            >
              <td>T-{{ transfer.transfer_code }}</td>
              <td>{{ dukaHotelDate(transfer.date_transferred) }}</td>
              <td>
                <i class="dripicons-user"></i>
                {{ transfer.receiver_firstname }}
                {{ transfer.receiver_lastname }}
              </td>
              <td>USD {{ addComma(transfer.amount_transfered) }}</td>
              <td>
                <span class="text-warning" v-if="!Number(transfer.transferred)"
                  >pending</span
                >
                <span class="text-success" v-else>Paid</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end table-responsive-->
    </div>
  </div>
</template>
<script>
export default {
  name: "Transfers",
  data: () => ({
    transfers: []
  }),
  created() {
    this.getTransfers();
  },
  methods: {
    getTransfers() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-transfers")
        .then(response => {
          this.transfers = response.data.transfers;
        });
    }
  }
};
</script>
