<template>
  <div class="taller-wrapper">
    <div class="container pb-5 mb-sm-4">
      <div class="row" v-if="orderCode">
        <div class="col-lg-8 mx-lg-auto col-12">
          <div class="pt-5">
            <div class="card jb-card py-3 mt-sm-3">
              <div class="card-body text-center">
                <h3 class="h4 pb-3">Thank you for your order!</h3>
                <p class="mb-2">
                  Your order has been placed and will be processed as soon as
                  possible.
                </p>
                <p class="mb-2">
                  Make sure you make note of your order number, which is
                  <strong>{{ orderCode }}.</strong>
                </p>
                <p>
                  You will be receiving an email shortly with confirmation of
                  your order. <u>You can now:</u>
                </p>

                <router-link
                  class="btn btn-outline-secondary"
                  :to="{ name: 'home' }"
                  >Go back shopping</router-link
                >
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'ordersearching' }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-map-pin"
                  >
                    <path
                      d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"
                    ></path>
                    <circle cx="12" cy="10" r="3"></circle></svg
                  >&nbsp;Track order
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderComplete",
  props: {
    orderCode: {
      type: String,
      default: null
    }
  },
  beforeMount() {
    if (!this.orderCode) {
      if (this.$route.query["order-code"])
        this.orderCode = this.$route.query["order-code"];
      else window.location.replace("/");
    }
  }
};
</script>
