<template>
  <div class="section home-send-money taller-wrapper">
    <section class="py-2">
      <div class="container mb-2">
        <div class="row">
          <div class="col-md-6 mx-auto" v-if="!verified">
            <div class="card jb-card text-center mb-3">
              <div class="card-body">
                <h4 class="card-title">Verfying your offer access token</h4>
                <p class="card-text">
                  Please wait while we are validating your offer access token...
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-5 mx-auto" v-else>
            <div class="card jb-card" v-if="!isEmpty(offer)">
              <div class="card-header">
                <h4>Offer Summary</h4>
              </div>
              <div class="card-body">
                <h5>
                  <span class="text-primary">
                    <small>Offer title:</small>
                  </span>
                  <br />
                  {{ offer.offer_title }}
                </h5>
                <hr />
                <h5>
                  <span class="text-primary">
                    <small>Offer Code:</small>
                  </span>
                  <br />
                  {{ offer.offer_code }}
                </h5>
                <hr />
                <div>
                  <h6>
                    Total Offer Amount
                    <span class="text-primary float-right">
                      {{
                        offer.offer_currency +
                          " " +
                          addComma(offer.offer_amount)
                      }}</span
                    >
                  </h6>
                  <h6>
                    V.A.T (
                    <i>18%</i> )
                    <span class="text-primary float-right"
                      >{{ offer.offer_currency }} 0.00</span
                    >
                  </h6>
                  <h6>
                    Charges Fee:
                    <span class="text-primary float-right"
                      >{{ offer.offer_currency }} 0.00</span
                    >
                  </h6>
                  <h6>
                    Grand Total
                    <span class="text-primary float-right"
                      >{{ offer.offer_currency }}
                      {{ addComma(offer.offer_amount) }}</span
                    >
                  </h6>
                  <p>
                    We accept following credit cards:&nbsp;&nbsp;
                    <img
                      class="d-inline-block align-middle"
                      :src="dukaURL + 'assets/images/credit-cards.png'"
                      style="width: 120px;"
                      alt="Cerdit Cards"
                    />
                  </p>
                  <button
                    type="button"
                    class="btn btn-block btn-filled form-btn"
                    @click="offerPayment"
                  >
                    <span
                      class="bh"
                      style="top: 59.125px; left: 95.5px;"
                    ></span>
                    <span>
                      Continue To Payment
                      <i class="dripicons-arrow-thin-right float-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="afrip-card" style="display:none">
      <form
        action="https://afripay.africa/checkout/index.php"
        method="post"
        id="afripayform"
      >
        <input type="hidden" name="amount" v-model="offer.offer_amount" />
        <input type="hidden" name="currency" v-model="offer.offer_currency" />
        <input type="hidden" name="comment" value="Paying for offer" />
        <input type="hidden" name="client_token" id="clientToken" />
        <input type="hidden" name="return_url" id="returnURL" value="" />
        <input
          type="hidden"
          name="firstname"
          v-model="currentUser.firstname"
          value
        />
        <input
          type="hidden"
          name="lastname"
          v-model="currentUser.lastname"
          value
        />
        <input type="hidden" name="street" value="Test" />
        <input type="hidden" name="city" value="Test" />
        <input type="hidden" name="state" value="Test" />
        <input type="hidden" name="zip_code" value="Test" />
        <input type="hidden" name="country" v-model="currentUser.country" />
        <input type="hidden" name="email" v-model="currentUser.email" />
        <input type="hidden" name="phone" v-model="currentUser.phone" />
        <input
          type="hidden"
          name="app_id"
          value="7116db164d6c4522e02154b01ddae64d"
        />
        <input type="hidden" name="app_secret" value="JDJ5JDEwJFQ3b0k2" />
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "OfferCheckout",
  data: () => ({
    verified: false,
    invalidCode: false,
    offer: {},
    payment_token: null,
    currentUser: {}
  }),
  created() {
    this.vaidateOffer();
    if (this.isDukaLoggedIn) this.getCurrentUser();
  },
  updated() {
    this.$nextTick(() => {
      if (this.isDukaLoggedIn)
        if (
          this.$route.query["redirect-request"] &&
          this.$route.query["redirect-request"] === "payment"
        )
          if (!this.isEmpty(this.offer)) this.proceedPayment();
    });
  },
  methods: {
    offerPayment() {
      if (this.isDukaLoggedIn) this.proceedPayment();
      else
        this.$router.push({
          name: "login",
          query: {
            "origin-request": "offer-checkout",
            "redirect-url":
              window.location.origin +
              "/offer-checkout/" +
              this.$route.params.code +
              "?payment-token=" +
              this.$route.query["payment-token"] +
              "&url-flag=" +
              this.$route.query["url-flag"]
          }
        });
    },
    proceedPayment() {
      document.getElementById("returnURL").value =
        "https://talar.rw?order-code=" + this.offer.id;
      document.getElementById("clientToken").value =
        this.$route.query["payment-token"] + "-company_offer";
      document.getElementById("afripayform").submit();
    },
    getCurrentUser() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-current-user")
        .then(response => {
          this.currentUser = {
            ...response.data.user_details,
            ...response.data.user
          };
        });
    },
    vaidateOffer() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "ap/validate-offer/offer/" + this.$route.params.code
        )
        .then(response => {
          this.invalidCode = response.data.error === true;
          this.verified = response.data.verified === true;
          if (response.data.verified) {
            this.offer = response.data.offer;
          }
        });
    }
  }
};
</script>
